<template>
  <section v-if="!state.isLoading" class="form__wrapper">
    <div class="container__wrapper">
      <div>
        <h5 class="container__header-title">{{ $t('configuration') }}</h5>
      </div>
      <div class="export-content">
        <div class="export-content-header">
          <div class="export-content-header__image">
            <img :src="exportParameters.imageUrl" />
          </div>
          <div class="export-content-header__text">
            <span class="integration__header-name">{{
              exportParameters.providerName
            }}</span>
            <span class="integration__description">
              {{ $t('data.exports.exportDescription') }}
              {{ exportParameters.providerName }}
            </span>
          </div>
        </div>
        <div class="export-content-body">
          <div v-if="!!exportParameters.name" class="export-content-inputs">
            <b-col lg="12" class="input__wrapper">
              <label>{{ $t('Name') }} *</label>
              <b-form-input
                id="integration-name"
                v-model.trim="exportParameters.name.value"
                :placeholder="$t('audience.enterName')"
                class="form__input"
                :class="{
                  'form__input--invalid':
                    exportParameters.name.errors.length > 0
                }"
                required
                @input="handleFormInput(state, [exportParameters.name])"
              />
            </b-col>
            <div
              v-for="(error, index) in exportParameters.name.errors"
              :key="index"
              class="export-content-inputs__errors"
            >
              {{ error }}
            </div>
            <div
              v-for="parameter in exportParameters.parameters"
              :key="parameter.parameterId"
            >
              <b-col lg="12" class="input__wrapper">
                <label>{{
                  parameter.parameterName + (parameter.required ? ' *' : '')
                }}</label>
                <b-form-textarea
                  v-if="parameter.parameterName === 'Credentials'"
                  id="export-credentials"
                  v-model="parameter.value.value"
                  rows="10"
                  max-rows="10"
                  no-resize
                  size="sm"
                />
                <b-form-input
                  v-else
                  :id="`exportParameters-${parameter.parameterName}`"
                  v-model.trim="parameter.value.value"
                  :placeholder="'Enter ' + parameter.parameterName + '...'"
                  class="form__input"
                  :class="{
                    'form__input--invalid': parameter.value.errors.length > 0
                  }"
                  :required="parameter.required"
                  @input="handleFormInput(state, [parameter.value])"
                />
              </b-col>
              <div
                v-for="(error, index) in parameter.value.errors"
                :key="index"
                class="export-content-inputs__errors"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer
      :is-loading="state.isLoading"
      :submitted="state.submitted"
      :cancel-id="`cancel-create-export`"
      :create-id="`add-export`"
      @back="cancel()"
      @create="saveExport()"
    />
  </section>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import { dataExports } from '@/api'
import { BCol, BFormInput, BFormTextarea } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive } from 'vue'

import {
  Footer,
  LoadingBar,

  // utility functions
  confirmChanges,
  handleFormInput
} from '@nodus/utilities-front'

export default {
  name: 'ExportCreate',
  components: {
    BCol,
    BFormInput,
    BFormTextarea,
    Footer,
    LoadingBar
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.state.submitted && !this.state.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, providerId } = vm.$route.params
    const store = vm.$store

    const state = reactive({
      submitted: false,
      isPristine: true,
      isLoading: false
    })

    const exportParameters = computed(() => store.getters['exports/parameters'])

    onBeforeMount(async () => {
      state.isLoading = true
      await store.dispatch('exports/getParameters', {
        id: organizationId,
        providerId
      })
      state.isLoading = false
    })

    const saveExport = async () => {
      const requestModel = {
        providerId,
        name: exportParameters.value.name.value,
        parameters: exportParameters.value.parameters.reduce((acc, curr) => {
          acc.push({
            parameterId: curr.parameterId,
            value: curr.value.value
          })
          return acc
        }, [])
      }
      state.submitted = true
      const {
        data: { errors, success }
      } = await dataExports.addExport(organizationId, requestModel)

      if (!success) {
        exportParameters.value.name.errors = errors.name || []
        exportParameters.value.parameters.forEach((item) => {
          /* eslint-disable no-param-reassign */
          item.value.errors = errors[item.parameterName] || []
        })
        state.submitted = false
      } else {
        vm.$router.push({ name: 'exports', params: { organizationId } })
      }
    }

    const cancel = () => {
      vm.$router.push({ name: 'exports', params: { organizationId } })
    }

    return {
      exportParameters,
      saveExport,
      cancel,
      state,
      handleFormInput
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/exports/index.scss';
@import '@/assets/scss/views/shared/index.scss';
</style>
