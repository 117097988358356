export default [
  {
    path: '/bisko/:organizationId/data/connections',
    name: 'connections',
    component: () => import('@/views/connections/Connections'),
    meta: {
      navActiveLink: 'connections',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Connect',
          active: true
        }
      ]
    }
  }
]
