<template>
  <section>
    <div v-if="!isLoading" class="view-screen__container">
      <div class="record-view__container">
        <div class="basic-information__container">
          <h5 class="container__title container__title--margin">
            {{ $t('data.basicInfo') }}
          </h5>
          <b-link
            v-if="record.canEdit && !pipelineNotFound"
            class="edit__button"
            :to="{
              name: 'record-edit',
              params: {
                organizationId: organizationId,
                recordId: recordId
              }
            }"
          >
            <GjIcon name="Edit" size="18" />
            <span>{{ $t('Edit') }}</span>
          </b-link>
          <div class="item__container">
            <span class="template__text">{{ $t('Name') }}</span>
            <span class="dynamic-data__text">{{ record.name }}</span>
          </div>
          <div class="item__container">
            <span class="template__text">{{ $t('data.alias') }}</span>
            <span class="dynamic-data__text">{{ record.alias }}</span>
          </div>
          <div class="item__container">
            <span class="template__text">{{ $t('Description') }}</span>
            <ReadMore
              :text="record.description"
              mode="view"
              :classes="['dynamic-data__text']"
            />
          </div>
          <div class="item__container item__container--center">
            <span class="template__text">{{ $t('data.recordType') }}</span>
            <GjIcon
              :hidden="record.recordChannel !== 1"
              name="Webpage"
              class="icon__margin"
            />
            <GjIcon
              :hidden="record.recordChannel !== 2"
              name="Mobile"
              class="icon__margin--mobile"
            />
            <GjIcon
              :hidden="record.recordChannel !== 3"
              name="Api"
              class="icon__margin"
            />
            <img
              :hidden="record.recordChannel !== 4"
              :src="require('@/assets/svgs/records/channel-profiler.svg')"
              class="icon__margin"
            />
            <span class="dynamic-data__text">{{
              getRecordType(record.recordChannel)
            }}</span>
          </div>
        </div>
        <div class="view-schemas__container">
          <h5 class="container__title">{{ $t('data.schema') }}</h5>
          <div class="record__table record__table--heading">
            <b-col class="record__table-column">
              <span class="record__table-header">{{
                $t('data.fieldType')
              }}</span>
            </b-col>
            <b-col cols="2" sm="3" class="record__table-column">
              <span class="record__table-header">{{ $t('Name') }}</span>
            </b-col>
            <b-col class="record__table-column">
              <span class="record__table-header">{{ $t('data.type') }}</span>
            </b-col>
            <b-col
              v-if="record.recordChannel === 4"
              class="record__table-column"
            >
              <span class="record__table-header">{{
                $t('data.designation')
              }}</span>
            </b-col>
            <b-col class="record__table-column">
              <span class="record__table-header">{{ $t('Required') }}</span>
            </b-col>
          </div>
          <div
            v-for="(schema, index) in record.allSchemas"
            :key="index"
            class="record__table"
          >
            <b-col class="record__table-column">
              <span class="record__table-item">{{ schema.fieldType }}</span>
            </b-col>
            <b-col cols="2" sm="3" class="record__table-column">
              <span class="record__table-item">{{ schema.name }}</span>
            </b-col>
            <b-col class="record__table-column">
              <span class="record__table-item">{{
                getSchemaType(schema.type)
              }}</span>
            </b-col>
            <b-col
              v-if="record.recordChannel === 4"
              class="record__table-column"
            >
              <div
                v-if="schema.designation"
                class="record__table-designation-item"
              >
                <img :src="getDesignation(schema.designation).icon" />
                <span>{{ getDesignation(schema.designation).text }}</span>
              </div>
            </b-col>
            <b-col class="record__table-column">
              <div class="record__table-designation-item">
                <img
                  v-if="schema.required"
                  src="@/assets/svgs/checkIcon.svg"
                  class="record__table-item"
                />
              </div>
            </b-col>
          </div>
        </div>
        <div v-if="pipelineNotFound" class="profiler__error-message">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.2517 5.147L3.65068 17.0287C2.91009 18.3618 3.87403 20 5.39899 20H18.6009C20.1259 20 21.0898 18.3618 20.3493 17.0287L13.7483 5.147C12.9863 3.77538 11.0137 3.77538 10.2517 5.147ZM13 9.00003C13 8.44775 12.5522 8.00003 12 8.00003C11.4477 8.00003 11 8.44775 11 9.00003V13C11 13.5523 11.4477 14 12 14C12.5522 14 13 13.5523 13 13V9.00003ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
              fill="#EF8C13"
            />
          </svg>
          <div>
            <h5>{{ $t('Warning') }}</h5>
            <p>{{ $t('data.tryRefreshingPage') }}.</p>
          </div>
        </div>
        <template
          v-else-if="getRecordType(record.recordChannel) === 'Profiler'"
        >
          <div class="profiler-section__container">
            <h5 class="container__title">{{ $t('Integration') }}</h5>
            <div class="item__container">
              <span class="template__text">{{
                $t('data.pipelineIntegration')
              }}</span>
              <span class="dynamic-data__text">{{ record.pipeline }}</span>
            </div>
          </div>
          <div class="profiler-section__container">
            <h5 class="container__title">{{ $t('audience.schedule') }}</h5>
            <div class="item__container">
              <span class="template__text template__text--no-break"
                >{{ $t('data.dataImportWillRun') }}
                <b>{{ record.scheduling }}</b></span
              >
            </div>
          </div>
          <ImportExecutions
            :import-executions="record.importExecutions"
            @update-table="getImportRuns"
          />
        </template>
      </div>
      <Footer view-mode @back="cancel()" />
    </div>
    <div v-else class="loading__container">
      <LoadingBar />
    </div>
  </section>
</template>

<script>
import store from '@/store'
import { BCol, BLink } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive, ref } from 'vue'
import ImportExecutions from './ImportExecutions.vue'
import {
  designationOptions,
  getRecordType,
  getSchemaType,
  reprocessTimeUnitOptions
} from './RecordConfig'

import { Footer, GjIcon, LoadingBar, ReadMore } from '@nodus/utilities-front'

export default {
  name: 'RecordView',
  components: {
    BCol,
    BLink,
    GjIcon,
    Footer,
    ReadMore,
    LoadingBar,
    ImportExecutions
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, recordId } = vm.$route.params

    const record = reactive({
      name: '',
      alias: '',
      description: '',
      id: '',
      recordChannel: '',
      allSchemas: [],
      canEdit: false,
      scheduling: '',
      pipeline: '',
      importExecutions: {
        runs: [],
        totalItems: 0,
        totalPages: 0,
        page: 0,
        loading: false
      }
    })

    const isLoading = ref(false)

    const getImportRuns = async () => {
      record.importExecutions.loading = true
      const { page } = record.importExecutions
      const {
        data: { runs, totalPages, totalItems }
      } = await store.dispatch('records/getRecordPipelineJob', {
        organizationId,
        recordId,
        page
      })
      record.importExecutions = {
        runs,
        totalPages,
        totalItems,
        page,
        loading: false
      }
    }

    onBeforeMount(async () => {
      isLoading.value = true
      const {
        name,
        alias,
        description,
        id,
        recordChannel,
        recordPresets,
        recordSchema,
        canEdit
      } = await store.dispatch('records/getRecord', {
        id: organizationId,
        recordId
      })
      record.name = name
      record.alias = alias
      record.description = description
      record.id = id
      record.canEdit = canEdit
      record.recordChannel = recordChannel
      /* eslint-disable no-param-reassign */
      recordSchema.forEach((schema) => {
        schema.fieldType = 'Custom'
        record.allSchemas.push(schema)
      })
      Object.keys(recordPresets).forEach((data) => {
        recordPresets[data]
          .filter((obj) => obj.selected)
          .forEach((preset) => {
            preset.fieldType = 'Preset'
            record.allSchemas.push(preset)
          })
      })

      if (getRecordType(recordChannel) === 'Profiler') {
        record.importExecutions.loading = true
        const response = await store.dispatch('records/getRecordPipelineJob', {
          organizationId,
          recordId
        })
        const { statusCode } = response

        if (statusCode === 404) {
          record.pipeline = 404
        } else {
          const { job, runs, totalPages, totalItems } = response.data
          record.pipeline = job.pipelineTitle
          record.importExecutions = {
            runs,
            totalPages,
            totalItems,
            page: 0,
            loading: false
          }

          let scheduling = 'only once'
          if (job.scheduleId !== 1) {
            const timeUnit = reprocessTimeUnitOptions.find(
              (opt) => opt.value === job.scheduleId
            )
            const frequency =
              job.scheduleFrequency === 1
                ? timeUnit.text.slice(0, -1)
                : `${job.scheduleFrequency} ${timeUnit.text}`
            scheduling = `every ${frequency}`
          }
          record.scheduling = scheduling
        }
      }

      isLoading.value = false
    })

    const getDesignation = (designation) =>
      designationOptions.find((opt) => opt.value === designation)

    const pipelineNotFound = computed(
      () =>
        getRecordType(record.recordChannel) === 'Profiler' &&
        record.pipeline === 404
    )

    const cancel = () => {
      vm.$router.push({
        name: 'records'
      })
    }

    return {
      record,
      organizationId,
      recordId,
      getRecordType,
      getSchemaType,
      getDesignation,
      getImportRuns,
      pipelineNotFound,
      isLoading,
      cancel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/records/record-view.scss';
@import '@/assets/scss/views/records/record-create.scss';
</style>
