import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'custom-name-header',
          width: '50%'
        },
        {
          label: 'Type',
          field: 'type',
          width: '20%'
        },
        {
          label: 'Created Date',
          field: 'insertedAt',
          width: '30%'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'not-sortable-headers',
          sortable: false
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    canCreate: false,
    export: [],
    parameters: [],
    providers: [],
    IDtoDelete: null
  },
  getters: {
    exports: (state) => state.table,
    export: (state) => state.export,
    canCreate: (state) => state.canCreate,
    parameters: (state) => state.parameters,
    providers: (state) => state.providers,
    IDtoDelete: (state) => state.IDtoDelete
  },
  actions: {
    async getExports({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/exports?${queryParamString}`
      )

      commit('SET_EXPORTS', destructJSONObject(response, 'dataExports'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
    },
    async getProviders({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/exports/providers`
      )
      commit('SET_PROVIDERS', destructJSONObject(response, 'providers'))
    },
    async getExport({ commit }, { id, exportId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/exports/${exportId}`
      )
      commit('SET_EXPORT', response.data.data)
      return response.data.data
    },
    async getParameters({ commit }, { id, providerId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/exports/providers/${providerId}/parameters`
      )
      commit('SET_PARAMETERS', response.data.data)
    }
  },
  mutations: {
    SET_EXPORTS(state, exports) {
      state.table.rows = exports
    },
    SET_PROVIDERS(state, providers) {
      state.providers = providers
    },
    SET_EXPORT(state, item) {
      state.export = item
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = {
        name: {
          value: '',
          errors: []
        },
        imageUrl: parameters.providerLogoUrl,
        providerName: parameters.providerName,
        parameters: parameters.providerParameters.reduce(
          (acc, curr) => [
            ...acc,
            {
              parameterId: curr.id,
              parameterName: curr.name,
              required: curr.required,
              type: curr.type,
              value: {
                value: '',
                errors: []
              }
            }
          ],
          []
        )
      }
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDtoDelete = id
    },
    SET_TABLE_PAGE(state, page) {
      state.table.page = page
    }
  }
}
