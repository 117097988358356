var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.data.column.field === "name"
      ? _c("span", { staticClass: "dark-gray-color name__container" }, [
          _c(
            "div",
            { staticClass: "name__wrapper" },
            [
              _c("IconHeading", {
                attrs: { id: _vm.data.row.id, data: _vm.data },
              }),
              _c("ReadMore", {
                attrs: {
                  text: _vm.data.row.name,
                  to: _vm.data.row.canView ? _vm.viewLocation : null,
                },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "type"
      ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.data.row.provider))])])
      : _vm.data.column.field === "insertedAt"
      ? _c("span", { staticClass: "created-date__and-actions" }, [
          _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.data.row.insertedAt)))]),
        ])
      : _vm._e(),
    _vm.data.column.field === "actions"
      ? _c(
          "div",
          [_c("DropdownActions", { attrs: { items: _vm.dropdownActions } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }