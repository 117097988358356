<template>
  <div class="connections__container">
    <b-tabs>
      <b-tab id="my-connections" title="My Connections" active>
        <div class="connections-list__container">
          <div class="table-list__header">
            <primary-create-button
              id="create-connection"
              :name="$t('data.connections.addConnection')"
              :can-add="canCreate"
              :is-loading="isLoading"
              :click-handler="openModal"
            />
            <SearchInputField
              id="search-my-connections"
              :data="myConnections"
            />
          </div>
          <div class="table__wrapper">
            <CustomTable
              :data="myConnections"
              type-of-table="connections"
              :is-pagination-enabled="true"
              :is-loading="isLoading"
              :empty-state-action="{
                canAdd: canCreate,
                action: openModal
              }"
              @update-table="updateMyConnectionsTable"
            >
              <template #row-data="{ data }">
                <ConnectionsRow :data="data" />
              </template>
            </CustomTable>
          </div>
        </div>
      </b-tab>

      <b-tab id="shared-connections" title="Shared Connections">
        <div class="connections-list__container">
          <div class="connections-list__header-row">
            <div class="shared__connections-header">
              <SearchInputField
                id="search-shared-connections"
                :data="sharedConnections"
              />
            </div>
            <div class="table__wrapper">
              <CustomTable
                :data="sharedConnections"
                type-of-table="shared-connections"
                :is-pagination-enabled="true"
                :is-loading="isSharedLoading"
                @update-table="updateSharedConnectionsTable"
              >
                <template #row-data="{ data }">
                  <ConnectionsRow :data="data" />
                </template>
              </CustomTable>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- Create Connections Modal -->
    <b-modal
      id="modal-connections-create"
      ref="connectionModal"
      :ok-title="!IDtoEdit && !isModalLoading ? $t('Create') : $t('Save')"
      :ok-disabled="isModalLoading"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      modal-class="connection__modal"
      centered
      size="md"
      :title="
        !IDtoEdit && !isModalLoading
          ? $t('data.connections.createTitle')
          : $t('data.connections.editTitle')
      "
      no-enforce-focus
      no-close-on-backdrop
      @ok="createConnection"
      @cancel="onModalClose"
      @close="onModalClose"
    >
      <div v-if="!isModalLoading" class="connection__modal-content">
        <p class="connection__modal-information">
          {{ $t('data.connections.connectionInformation') }}
          {{ $t('data.connections.documentationLink') }}
          <a
            :href="`${whitelabel.documentationUrl}/intro#introduction-to-connections`"
            target="_blank"
            >documentation</a
          >.
        </p>
        <div class="connection__modal-input__wrapper">
          <label>{{ $t('data.connections.connectionName') }} *</label>
          <b-form-input
            id="connection-name"
            v-model.trim="connection.connectionName.value"
            :disabled="!!IDtoEdit"
            :placeholder="$t('data.connections.enterConnectionName')"
            class="connection__modal-form__input"
            :class="{
              'connection__modal-form__input--invalid':
                connection.connectionName.errors.length > 0
            }"
            @input="handleFormInput((form = null), [connection.connectionName])"
          />
          <div
            v-for="(error, index) in connection.connectionName.errors"
            :key="index"
            class="connection__modal-error-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>
        <div class="connection__modal-input__wrapper">
          <label>{{ $t('data.connections.organizationAlias') }} *</label>
          <b-form-input
            id="connection-alias"
            v-model.trim="connection.organizationAlias.value"
            :disabled="!!IDtoEdit"
            :placeholder="$t('data.connections.enterOrganizationAlias')"
            class="connection__modal-form__input"
            :class="{
              'connection__modal-form__input--invalid':
                connection.organizationAlias.errors.length > 0
            }"
            @input="
              handleFormInput((form = null), [connection.organizationAlias])
            "
          />
          <div
            v-for="(error, index) in connection.organizationAlias.errors"
            :key="index"
            class="connection__modal-error-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>

        <div class="mt-50">
          <div class="connection__checkbox-container">
            <b-form-checkbox
              id="share-events"
              v-model="connection.shareEvents"
              name="share-events"
              @input="handleFormInput((form = null), [connection.model])"
            >
              {{ $t('data.connections.shareEvents') }}
            </b-form-checkbox>
            <span id="events-informations">
              <GjIcon name="Info" size="18" class="ml-50"></GjIcon>
            </span>
            <b-tooltip
              target="events-informations"
              triggers="hover"
              placement="bottom"
              custom-class="connection__modal-tooltip-info"
            >
              {{ $t('data.connections.eventsInformations') }}
            </b-tooltip>
          </div>
          <div class="connection__checkbox-container">
            <b-form-checkbox
              id="share-records"
              v-model="connection.shareRecords"
              name="share-records"
              @input="handleFormInput((form = null), [connection.model])"
            >
              {{ $t('data.connections.shareRecords') }}
            </b-form-checkbox>
            <span id="records-informations">
              <GjIcon name="Info" size="18" class="ml-50"></GjIcon>
            </span>
            <b-tooltip
              target="records-informations"
              triggers="hover"
              placement="bottom"
              custom-class="connection__modal-tooltip-info"
            >
              {{ $t('data.connections.recordsInformations') }}
            </b-tooltip>
          </div>
          <div
            v-for="(error, index) in connection.model.errors"
            :key="index"
            class="connection__modal-error-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
      <div v-else class="connection__modal-body--height">
        <div class="loading__container">
          <LoadingBar />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { connections } from '@/api'
import ConnectionsRow from '@/components/table-data/ConnectionsRow.vue'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  CustomTable,
  GjIcon,
  LoadingBar,
  PrimaryCreateButton,
  SearchInputField,

  // utility functions
  handleFormInput
} from '@nodus/utilities-front'
import {
  BFormCheckbox,
  BFormInput,
  BTab,
  BTabs,
  BTooltip,
  VBModal
} from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'Connections',
  directives: {
    'b-modal': VBModal
  },
  components: {
    BTooltip,
    BFormInput,
    BFormCheckbox,
    CustomTable,
    PrimaryCreateButton,
    SearchInputField,
    GjIcon,
    LoadingBar,
    BTabs,
    BTab,
    ConnectionsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const connectionModal = ref('')

    const myConnections = computed(
      () => store.getters['connections/myConnections']
    )
    const sharedConnections = computed(
      () => store.getters['connections/sharedConnections']
    )
    const connection = computed(() => store.getters['connections/connection'])

    const IDtoEdit = computed(() => store.getters['connections/IDtoEdit'])
    const canCreate = computed(() => store.getters['connections/canCreate'])

    const isLoading = ref(true)
    const isSharedLoading = ref(true)
    const isModalLoading = computed(
      () => store.getters['connections/isModalLoading']
    )

    onMounted(async () => {
      store.dispatch('connections/getSharedConnections', { id: organizationId })
      await store.dispatch('connections/getMyConnections', {
        id: organizationId
      })
      isSharedLoading.value = false
      isLoading.value = false
    })

    const openModal = () => connectionModal.value.show()

    const clearValues = () => {
      connection.value.connectionName.value = ''
      connection.value.organizationAlias.value = ''
      connection.value.shareEvents = false
      connection.value.shareRecords = false
    }

    const clearErrors = () => {
      connection.value.connectionName.errors = []
      connection.value.organizationAlias.errors = []
      connection.value.model.errors = []
    }

    const onModalClose = async () => {
      setTimeout(() => {
        clearValues()
        clearErrors()
        store.commit('connections/SET_ID_TO_EDIT', '')
      }, 200)
    }

    const updateMyConnectionsTable = async () => {
      isLoading.value = true
      await store.dispatch('connections/getMyConnections', {
        id: organizationId
      })
      isLoading.value = false
    }

    const updateSharedConnectionsTable = async () => {
      isSharedLoading.value = true
      await store.dispatch('connections/getSharedConnections', {
        id: organizationId
      })
      isSharedLoading.value = false
    }

    const createConnection = async (modalEvent) => {
      modalEvent.preventDefault()
      let response = null
      if (IDtoEdit.value) {
        response = await connections.updateConnection(
          organizationId,
          IDtoEdit.value,
          connection.value
        )
      } else {
        response = await connections.addConnection(
          organizationId,
          connection.value
        )
      }

      const {
        data: { errors, success }
      } = response
      if (success) {
        connectionModal.value.hide()
        onModalClose()
        updateMyConnectionsTable()
      } else {
        clearErrors()
        Object.keys(errors).forEach((key) => {
          connection.value[key].errors = errors[key]
        })
      }
    }

    return {
      myConnections,
      sharedConnections,
      canCreate,
      isLoading,
      isSharedLoading,
      openModal,
      createConnection,
      onModalClose,
      updateMyConnectionsTable,
      updateSharedConnectionsTable,
      connection,
      IDtoEdit,
      isModalLoading,
      handleFormInput,
      connectionModal,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/connections/index.scss';
</style>
