var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("primary-create-button", {
            attrs: {
              id: "create-record",
              name: _vm.$t("data.createNew"),
              "is-loading": _vm.isLoading,
              "can-add": _vm.canCreate,
              "click-handler": _vm.createRecordForm,
            },
          }),
          _c("SearchInputField", {
            attrs: { id: "search-records", data: _vm.records },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table__wrapper" },
        [
          _c("CustomTable", {
            attrs: {
              data: _vm.records,
              "type-of-table": "records",
              "is-pagination-enabled": true,
              "is-loading": _vm.isLoading,
              "empty-state-action": {
                canAdd: _vm.canCreate,
                action: _vm.createRecordForm,
              },
            },
            on: { "update-table": _vm.getData },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("RecordsRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-tracking-code-Web",
            "modal-class": "record__modal",
            "ok-only": "",
            "ok-title": _vm.$t("close"),
            "ok-variant": "outline-secondary",
            centered: "",
            size: "lg",
            title: `${_vm.$t("accountManagement.trackingCode")} \n ${
              _vm.record.name
            }`,
            "no-enforce-focus": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c("div", { staticClass: "record__modal-content" }, [
            _c(
              "div",
              { staticClass: "record__modal-content-name" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("data.recordName")))]),
                _c("b-form-input", {
                  staticClass: "form__input--dark",
                  attrs: { value: _vm.record.name, readonly: "" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "documentation-info mt-1" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("data.trackingDocumentationLink")) + " "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: `${_vm.whitelabel.documentationUrl}/websdk#record-collection`,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                ),
                _vm._v("."),
              ]),
            ]),
            _c("div", { staticClass: "content-code" }, [
              !_vm.isModalLoading
                ? _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("accountManagement.trackingCode")) +
                            " "
                        ),
                      ]),
                      _c("b-form-textarea", {
                        staticClass: "tracking-code",
                        attrs: {
                          rows: "10",
                          "max-rows": "10",
                          "no-resize": "",
                          size: "sm",
                          plaintext: "",
                          value: _vm.trackingCode.WEB,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "copy-code",
                          attrs: { id: "copy-tracking-code" },
                          on: {
                            click: function ($event) {
                              return _vm.copyCode(_vm.trackingCode.WEB)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            staticClass: "mr-50",
                            attrs: { name: "Copy", size: "22" },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("accountManagement.copyToClipboard")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "loading__container" },
                    [_c("LoadingBar")],
                    1
                  ),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-tracking-code-Mobile",
            "modal-class": "record__modal",
            "ok-only": "",
            "ok-title": _vm.$t("close"),
            "ok-variant": "outline-secondary",
            centered: "",
            size: "lg",
            title: `${_vm.$t("accountManagement.trackingCode")} \n ${
              _vm.record.name
            }`,
            "no-enforce-focus": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c("div", { staticClass: "record__modal-content" }, [
            _c(
              "div",
              { staticClass: "record__modal-content-name" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("data.recordName")))]),
                _c("b-form-input", {
                  staticClass: "form__input--dark",
                  attrs: { value: _vm.record.name, readonly: "" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "documentation-info mt-1" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("data.trackingDocumentationLink")) + " "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: `${_vm.whitelabel.documentationUrl}/websdk#record-collection`,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                ),
                _vm._v("."),
              ]),
            ]),
            _c("div", { staticClass: "content-code-mobile" }, [
              !_vm.isModalLoading
                ? _c(
                    "div",
                    [
                      _c(
                        "b-tabs",
                        { attrs: { align: "left" } },
                        _vm._l(_vm.mobileTypes, function (type, index) {
                          return _c(
                            "b-tab",
                            { key: index, attrs: { id: type, title: type } },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("accountManagement.trackingCode")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("b-form-textarea", {
                                staticClass: "tracking-code",
                                attrs: {
                                  rows: "10",
                                  "max-rows": "10",
                                  "no-resize": "",
                                  size: "sm",
                                  plaintext: "",
                                  value: _vm.trackingCode[type],
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "copy-code",
                                  attrs: { id: "copy-tracking-code" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyCode(
                                        _vm.trackingCode[type]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("GjIcon", {
                                    staticClass: "mr-50",
                                    attrs: { name: "Copy", size: "22" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "accountManagement.copyToClipboard"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "loading__container" },
                    [_c("LoadingBar")],
                    1
                  ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }