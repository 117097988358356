<template>
  <section>
    <div class="table-list__header">
      <primary-create-button
        id="create-record"
        :name="$t('data.createNew')"
        :is-loading="isLoading"
        :can-add="canCreate"
        :click-handler="createRecordForm"
      />
      <SearchInputField id="search-records" :data="records" />
    </div>
    <div class="table__wrapper">
      <CustomTable
        :data="records"
        type-of-table="records"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canCreate,
          action: createRecordForm
        }"
        @update-table="getData"
      >
        <template #row-data="{ data }">
          <RecordsRow :data="data" />
        </template>
      </CustomTable>
    </div>

    <b-modal
      id="modal-tracking-code-Web"
      modal-class="record__modal"
      ok-only
      :ok-title="$t('close')"
      ok-variant="outline-secondary"
      centered
      size="lg"
      :title="`${$t('accountManagement.trackingCode')} \n ${record.name}`"
      no-enforce-focus
      no-close-on-backdrop
    >
      <div class="record__modal-content">
        <div class="record__modal-content-name">
          <span>{{ $t('data.recordName') }}</span>
          <b-form-input
            :value="record.name"
            readonly
            class="form__input--dark"
          />
        </div>
        <div class="documentation-info mt-1">
          <span
            >{{ $t('data.trackingDocumentationLink') }}
            <a
              :href="`${whitelabel.documentationUrl}/websdk#record-collection`"
              target="_blank"
              >{{ $t('Documentation').toLowerCase() }}</a
            >.</span
          >
        </div>
        <div class="content-code">
          <div v-if="!isModalLoading">
            <span>
              {{ $t('accountManagement.trackingCode') }}
            </span>
            <b-form-textarea
              class="tracking-code"
              rows="10"
              max-rows="10"
              no-resize
              size="sm"
              plaintext
              :value="trackingCode.WEB"
            />
            <div
              id="copy-tracking-code"
              class="copy-code"
              @click="copyCode(trackingCode.WEB)"
            >
              <GjIcon name="Copy" class="mr-50" size="22" />
              <span>{{ $t('accountManagement.copyToClipboard') }}</span>
            </div>
          </div>
          <div v-else class="loading__container">
            <LoadingBar />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-tracking-code-Mobile"
      modal-class="record__modal"
      ok-only
      :ok-title="$t('close')"
      ok-variant="outline-secondary"
      centered
      size="lg"
      :title="`${$t('accountManagement.trackingCode')} \n ${record.name}`"
      no-enforce-focus
      no-close-on-backdrop
    >
      <div class="record__modal-content">
        <div class="record__modal-content-name">
          <span>{{ $t('data.recordName') }}</span>
          <b-form-input
            :value="record.name"
            readonly
            class="form__input--dark"
          />
        </div>
        <div class="documentation-info mt-1">
          <span
            >{{ $t('data.trackingDocumentationLink') }}
            <a
              :href="`${whitelabel.documentationUrl}/websdk#record-collection`"
              target="_blank"
              >{{ $t('Documentation').toLowerCase() }}</a
            >.</span
          >
        </div>
        <div class="content-code-mobile">
          <div v-if="!isModalLoading">
            <b-tabs align="left">
              <b-tab
                v-for="(type, index) in mobileTypes"
                :id="type"
                :key="index"
                :title="type"
              >
                <span>
                  {{ $t('accountManagement.trackingCode') }}
                </span>
                <b-form-textarea
                  class="tracking-code"
                  rows="10"
                  max-rows="10"
                  no-resize
                  size="sm"
                  plaintext
                  :value="trackingCode[type]"
                />
                <div
                  id="copy-tracking-code"
                  class="copy-code"
                  @click="copyCode(trackingCode[type])"
                >
                  <GjIcon name="Copy" class="mr-50" size="22" />
                  <span>{{ $t('accountManagement.copyToClipboard') }}</span>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-else class="loading__container">
            <LoadingBar />
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import RecordsRow from '@/components/table-data/RecordsRow.vue'
import router from '@/router'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  CustomTable,
  GjIcon,
  LoadingBar,
  PrimaryCreateButton,
  SearchInputField,
  // utility functions
  showToast
} from '@nodus/utilities-front'
import { useClipboard } from '@vueuse/core'
import { BFormInput, BFormTextarea, BModal, BTab, BTabs } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'Records',
  components: {
    GjIcon,
    // Bootstrap Components
    BFormInput,
    BModal,
    BFormTextarea,
    BTabs,
    BTab,
    PrimaryCreateButton,
    CustomTable,
    SearchInputField,
    LoadingBar,
    RecordsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const store = vm.$store

    const isModalLoading = computed(() => store.getters['records/modalLoading'])
    const isLoading = ref(false)

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('records/getRecords', { id: organizationId })
      isLoading.value = false
    }

    onMounted(async () => getData())

    const records = computed(() => store.getters['records/records'])
    const record = computed(() => store.getters['records/record'])
    const trackingCode = computed(() => store.getters['records/trackingCode'])
    const canCreate = computed(() => store.getters['records/canCreate'])

    const mobileTypes = ['Swift', 'Kotlin', 'Java', 'Objective-C']

    const createRecordForm = () => {
      router.push({
        name: 'record-create',
        params: { organizationId }
      })
    }

    const { copy } = useClipboard()

    const copyCode = (code) => {
      copy(code)
      showToast('success', 'Text copied!')
    }

    return {
      isLoading,
      isModalLoading,
      records,
      canCreate,
      createRecordForm,
      copyCode,
      record,
      trackingCode,
      getData,
      mobileTypes,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/records/record-create.scss';
.record__modal {
  .tabs {
    ul.nav.nav-tabs {
      border-bottom: 1px solid #e0e5eb;
      margin-bottom: 16px;
      li {
        a.nav-link {
          color: #667c99;
          font-weight: normal;
          font-size: 14px;
          position: relative;
          &[aria-selected='true'] {
            color: #052d61;
            font-weight: 500;
            border: none;
            &::after {
              content: '';
              height: 2px;
              width: 100%;
              border-radius: 1000px;
              background: #1d79f2;
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }
        }
      }
    }
    .nav-vertical {
      ul.nav.nav-tabs {
        border-bottom-color: transparent;
      }
    }
  }
}
</style>
