export default [
  {
    path: '/bisko/:organizationId/data',
    redirect: '/bisko/:organizationId/data/records'
  },
  {
    path: '/bisko/:organizationId/data/records',
    name: 'records',
    component: () => import('@/views/records/Records'),
    meta: {
      navActiveLink: 'records',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Records',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/data/records/create',
    name: 'record-create',
    component: () => import('@/views/records/RecordCreate'),
    meta: {
      navActiveLink: 'record-create',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Records',
          to: '/records'
        },
        {
          text: 'Create Record',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/data/records/edit/:recordId',
    name: 'record-edit',
    component: () => import('@/views/records/RecordCreate'),
    meta: {
      navActiveLink: 'record-edit',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Records',
          to: '/records'
        },
        {
          text: 'Edit Record',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/data/records/view/:recordId',
    name: 'record-view',
    component: () => import('@/views/records/RecordView'),
    meta: {
      navActiveLink: 'record-view',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Records',
          to: '/records'
        },
        {
          text: 'View Record',
          active: true
        }
      ]
    }
  }
]
