/* eslint-disable global-require */

const getRecordType = (type) => {
  const types = {
    1: "Web",
    2: "Mobile",
    3: "API",
    4: "Profiler",
  };
  return types[type];
};

const getSchemaType = (type) => {
  const types = {
    0: "Small Integer",
    1: "Integer",
    2: "UInt16",
    3: "UInt32",
    4: "Long Integer",
    5: "UInt64",
    6: "String",
    7: "Fixed String",
    8: "Array of Strings",
    9: "Array of Integers",
    10: "Key Value Pair",
    11: "Date",
    12: "DateTime",
    13: "Decimal",
    14: "Boolean",
    15: "Nested",
    16: "Mixed",
    17: "Array of Doubles",
    18: "Double",
  };
  return types[type];
};

const reprocessOperatorOptions = [
  { text: "Once", value: 1 },
  { text: "Every", value: 2 },
];

const reprocessTimeUnitOptions = [
  { text: "hours", value: 3 },
  { text: "days", value: 4 },
  { text: "weeks", value: 6 },
  { text: "months", value: 7 },
];

const designationOptions = [
  {
    text: "Identifier",
    value: 1,
    icon: require("@/assets/svgs/records/designation-identifier.svg"),
  },
  {
    text: "Email",
    value: 2,
    icon: require("@/assets/svgs/records/designation-email.svg"),
  },
  {
    text: "BiskoID",
    value: 3,
    icon: require("@/assets/svgs/records/designation-biskoid.svg"),
  },
  {
    text: "UserID",
    value: 4,
    icon: require("@/assets/svgs/records/designation-userid.svg"),
  },
  {
    text: "UserAgent",
    value: 5,
    icon: require("@/assets/svgs/records/designation-useragent.svg"),
  },
  {
    text: "URL",
    value: 6,
    icon: require("@/assets/svgs/records/designation-url.svg"),
  },
  {
    text: "IP",
    value: 7,
    icon: require("@/assets/svgs/records/designation-ip.svg"),
  },
  {
    text: "EventTime",
    value: 8,
    icon: require("@/assets/svgs/records/designation-eventtime.svg"),
  },
];

const presetDesignations = {
  Device: 5,
  Page: 6,
  Utm: 6,
  GeoLocation: 7,
};

export {
  getRecordType,
  getSchemaType,
  reprocessOperatorOptions,
  reprocessTimeUnitOptions,
  designationOptions,
  presetDesignations,
};
