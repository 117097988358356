var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.formState.isLoading
        ? _c(
            "div",
            { staticClass: "loading__container" },
            [_c("LoadingBar")],
            1
          )
        : _c(
            "div",
            { staticClass: "form__wrapper" },
            [
              _c(
                "div",
                {
                  staticClass: "container__wrapper container__wrapper--height",
                },
                [
                  _c("div", { staticClass: "container__header" }, [
                    _c("h5", { staticClass: "container__header-title" }, [
                      _vm._v(_vm._s(_vm.$t("data.basicInfo"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "container__content" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "6" },
                        },
                        [
                          _c("label", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                          ]),
                          _c("b-form-input", {
                            staticClass: "form__input",
                            class: {
                              "form__input--invalid":
                                _vm.recordsObject.name.errors.length > 0,
                            },
                            attrs: {
                              id: "record-name",
                              placeholder: _vm.$t("data.recordNamePlaceholder"),
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.formState, [
                                  _vm.recordsObject.name,
                                ])
                              },
                            },
                            model: {
                              value: _vm.recordsObject.name.value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.recordsObject.name,
                                  "value",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "recordsObject.name.value",
                            },
                          }),
                          _vm.recordsObject.name.errors.length > 0
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.recordsObject.name.errors,
                                  function (error, i) {
                                    return _c(
                                      "span",
                                      {
                                        key: i,
                                        staticClass: "alias__info--error",
                                      },
                                      [_vm._v(" " + _vm._s(error) + " ")]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "6" },
                        },
                        [
                          _c("label", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("data.alias")) + " *"),
                          ]),
                          _c(
                            "b-input-group",
                            {
                              class: {
                                border__error:
                                  (!_vm.aliasValidation.isValid &&
                                    _vm.aliasValidation.isProcessed) ||
                                  _vm.recordsObject.alias.errors.length > 0,
                              },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.recordId
                                    ? {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("b-input-group-text", [
                                              !_vm.aliasValidation
                                                .isProcessed ||
                                              _vm.aliasValidation.isProcessing
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        svg__animate:
                                                          _vm.aliasValidation
                                                            .isProcessing,
                                                      },
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        attrs: {
                                                          name: "LoaderAlt",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.aliasValidation.isValid &&
                                              !_vm.aliasValidation
                                                .isProcessing &&
                                              _vm.aliasValidation.isProcessed
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "icon__check",
                                                        attrs: {
                                                          name: "Check",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.aliasValidation.isValid &&
                                              !_vm.aliasValidation
                                                .isProcessing &&
                                              _vm.aliasValidation.isProcessed
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "icon__close",
                                                        attrs: {
                                                          name: "Close",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form__input",
                                class: { "form__input--alias": !_vm.recordId },
                                attrs: {
                                  id: "record-alias",
                                  placeholder: _vm.$t("data.aliasPlaceholder"),
                                  disabled: !!_vm.recordId,
                                  type: "text",
                                },
                                on: {
                                  keyup: function ($event) {
                                    _vm.formatRecordAlias(),
                                      _vm.validateRecordAlias()
                                  },
                                  input: function ($event) {
                                    return _vm.handleFormInput(_vm.formState, [
                                      _vm.recordsObject.alias,
                                    ])
                                  },
                                },
                                model: {
                                  value: _vm.recordsObject.alias.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.recordsObject.alias,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "recordsObject.alias.value",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.recordId &&
                          (!_vm.aliasValidation.isProcessed ||
                            _vm.aliasValidation.isValid)
                            ? _c("span", { staticClass: "alias__info" }, [
                                _vm._v(
                                  " *" + _vm._s(_vm.$t("data.aliasInfo")) + " "
                                ),
                              ])
                            : _vm._e(),
                          !_vm.aliasValidation.isValid
                            ? _c(
                                "span",
                                { staticClass: "alias__info--error" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.aliasValidation.errorMessage) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "6" },
                        },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("Description")) + " "),
                          ]),
                          _c("b-form-textarea", {
                            staticClass:
                              "form__input form__input-textarea record-textarea",
                            class: {
                              "form__input--invalid":
                                _vm.recordsObject.description.errors.length > 0,
                            },
                            attrs: {
                              id: "record-description",
                              placeholder: _vm.$t(
                                "data.descriptionPlaceholder"
                              ),
                              rows: "3",
                              "no-resize": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.formState, [
                                  _vm.recordsObject.description,
                                ])
                              },
                            },
                            model: {
                              value: _vm.recordsObject.description.value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.recordsObject.description,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "recordsObject.description.value",
                            },
                          }),
                          _vm._l(
                            _vm.recordsObject.description.errors,
                            function (error, errIndex) {
                              return _c("div", { key: errIndex }, [
                                _c(
                                  "span",
                                  { staticClass: "alias__info--error" },
                                  [_vm._v(_vm._s(error))]
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "6" },
                        },
                        [
                          _c("label", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("data.recordType")) + " *"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "records__wrapper" },
                            _vm._l(_vm.channelOptions, function (option) {
                              return _c(
                                "div",
                                _vm._g(
                                  {
                                    key: option.id,
                                    staticClass: "records__wrapper-content",
                                    class: {
                                      "records__wrapper-content--disabled":
                                        !!_vm.recordId || option.disabled,
                                      "records__wrapper-content--active":
                                        option.id ===
                                        _vm.recordsObject.recordChannel,
                                    },
                                    attrs: { id: `${option.text}` },
                                  },
                                  !_vm.recordId && !option.disabled
                                    ? { click: () => _vm.chooseRecord(option) }
                                    : {}
                                ),
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.recordsObject.recordChannel,
                                        expression:
                                          "recordsObject.recordChannel",
                                      },
                                    ],
                                    attrs: {
                                      id: option.text,
                                      type: "radio",
                                      name: "records",
                                      disabled:
                                        !!_vm.recordId || option.disabled,
                                    },
                                    domProps: {
                                      value: option.id,
                                      checked: _vm._q(
                                        _vm.recordsObject.recordChannel,
                                        option.id
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.recordsObject,
                                          "recordChannel",
                                          option.id
                                        )
                                      },
                                    },
                                  }),
                                  option.disabled
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: option.text,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("data.notEnabledMessage")
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "records__wrapper-content-info",
                                    },
                                    [
                                      option.icon
                                        ? _c("GjIcon", {
                                            attrs: { name: option.icon },
                                          })
                                        : _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "currentColor",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M14.0702 16.25C14.4213 14.3994 16.0472 13 18 13C20.2091 13 22 14.7909 22 17C22 19.2091 20.2091 21 18 21C16.0472 21 14.4213 19.6006 14.0702 17.75H10.9441C10.9809 17.5054 11 17.2549 11 17C11 16.7451 10.9809 16.4946 10.9441 16.25H14.0702ZM15.6145 16.25H18C18.4142 16.25 18.75 16.5858 18.75 17C18.75 17.4142 18.4142 17.75 18 17.75H15.6145C15.933 18.7643 16.8806 19.5 18 19.5C19.3807 19.5 20.5 18.3807 20.5 17C20.5 15.6193 19.3807 14.5 18 14.5C16.8806 14.5 15.933 15.2357 15.6145 16.25Z",
                                                },
                                              }),
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M6 13C6.48463 13 6.94914 13.0862 7.37902 13.2441L8.8135 10.8533C9.19787 11.1715 9.63064 11.4333 10.0991 11.626L8.66469 14.0168C9.48417 14.7492 10 15.8144 10 17C10 19.2091 8.20914 21 6 21C3.79086 21 2 19.2091 2 17C2 14.7909 3.79086 13 6 13ZM6 19.5C4.61929 19.5 3.5 18.3807 3.5 17C3.5 15.6193 4.61929 14.5 6 14.5C6.20119 14.5 6.39684 14.5238 6.58428 14.5686L5.35686 16.6143C5.14375 16.9695 5.25892 17.4302 5.61411 17.6433C5.9693 17.8564 6.42999 17.7413 6.6431 17.3861L7.87019 15.3409C8.26203 15.7823 8.5 16.3634 8.5 17C8.5 18.3807 7.38071 19.5 6 19.5Z",
                                                },
                                              }),
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M16 7C16 8.18563 15.4842 9.25078 14.6647 9.98327L16.0991 12.374C15.6306 12.5667 15.1979 12.8286 14.8135 13.1468L13.379 10.7559C12.9491 10.9138 12.4846 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM12.6433 6.61424L13.8702 8.6591C14.262 8.21772 14.5 7.63665 14.5 7C14.5 5.61929 13.3807 4.5 12 4.5C10.6193 4.5 9.5 5.61929 9.5 7C9.5 8.38071 10.6193 9.5 12 9.5C12.2012 9.5 12.3968 9.47624 12.5842 9.43136L11.357 7.38599C11.1439 7.0308 11.2591 6.57011 11.6143 6.35699C11.9694 6.14388 12.4301 6.25906 12.6433 6.61424Z",
                                                },
                                              }),
                                            ]
                                          ),
                                      _c("label", [
                                        _vm._v(_vm._s(option.text)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "container__wrapper container__wrapper--height",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__header documentation__header--padding",
                    },
                    [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(_vm._s(_vm.$t("data.schema")) + " *"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "documentation__info-text mb-50" },
                    [
                      _c("GjIcon", {
                        staticClass: "mr-50",
                        attrs: { name: "Info", size: "18" },
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("data.recordsDocumentationLink")) + " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-records`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Documentation").toLowerCase())
                            ),
                          ]
                        ),
                        _vm._v("."),
                      ]),
                    ],
                    1
                  ),
                  _c("UploadListModal", {
                    attrs: {
                      "upload-type": "schema",
                      "upload-function": _vm.records.uploadFile,
                      "upload-button-text": "Upload CSV or JSON",
                      "upload-info": _vm.$t("data.uploadInfo"),
                      "file-types": ["CSV", "JSON"],
                      "file-size-limit": 1048576,
                      "show-review-modal": false,
                    },
                    on: { "add-file-entries": _vm.addSchemaRowsFromFile },
                    scopedSlots: _vm._u([
                      {
                        key: "fileContent",
                        fn: function (data) {
                          return [_c("div", [_vm._v(_vm._s(data.fileContent))])]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "div",
                    { staticClass: "schema__wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "record__table record__table--heading" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "record__table-column",
                              attrs: { lg: "4", md: "4", sm: "4" },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("Name")))])]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "record__table-column",
                              attrs: { lg: "3", md: "3", sm: "3" },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("data.type")))])]
                          ),
                          _vm.recordsObject.recordChannel === 4
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "record__table-column",
                                  attrs: { lg: "3", md: "3", sm: "3" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("data.designation"))),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              staticClass: "record__table-column",
                              attrs: { lg: "2", md: "2", sm: "2" },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("Required")))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "draggable",
                        {
                          staticClass: "records__draggable-area",
                          attrs: {
                            move: _vm.onMove,
                            handle: ".draggable",
                            "ghost-class": "disabled-ghost",
                            animation: "150",
                          },
                          model: {
                            value: _vm.recordsObject.recordSchema,
                            callback: function ($$v) {
                              _vm.$set(_vm.recordsObject, "recordSchema", $$v)
                            },
                            expression: "recordsObject.recordSchema",
                          },
                        },
                        _vm._l(
                          _vm.recordsObject.recordSchema,
                          function (schema, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "record__table" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "record__table--name-col",
                                    attrs: { lg: "4", md: "4", sm: "4" },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "records__arrow",
                                      class: {
                                        "records__arrow--multiple":
                                          _vm.recordsObject.recordSchema
                                            .length > 1 && index !== 0,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "records__arrow--or":
                                            index ===
                                            _vm.recordsObject.recordSchema
                                              .length -
                                              1,
                                        },
                                      },
                                      [
                                        index ===
                                        _vm.recordsObject.recordSchema.length -
                                          1
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "btn records__or-btn",
                                                attrs: { id: "add-schema-row" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addRow()
                                                  },
                                                },
                                              },
                                              [
                                                _c("GjIcon", {
                                                  attrs: {
                                                    name: "Plus",
                                                    size: "18",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("b-form-input", {
                                      staticClass: "form__input",
                                      class: {
                                        "form__input--invalid":
                                          schema.name.errors.length > 0,
                                      },
                                      attrs: {
                                        id: "schema-name",
                                        placeholder: "Enter name",
                                        type: "text",
                                        disabled:
                                          !!_vm.recordId && schema.disabled,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          schema.name.value = schema.name.value
                                            .trim()
                                            .replaceAll(/\s+/g, "_")
                                        },
                                        input: function ($event) {
                                          return _vm.handleFormInput(
                                            _vm.formState,
                                            [
                                              schema.name,
                                              _vm.recordsObject.recordSchemas,
                                            ]
                                          )
                                        },
                                      },
                                      model: {
                                        value: schema.name.value,
                                        callback: function ($$v) {
                                          _vm.$set(schema.name, "value", $$v)
                                        },
                                        expression: "schema.name.value",
                                      },
                                    }),
                                    schema.name.errors.length > 0
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            schema.name.errors,
                                            function (errors, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "alias__info--error",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "alias__info--error__message",
                                                    },
                                                    [_vm._v(_vm._s(errors))]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "record__table-column--width",
                                    attrs: { lg: "3", md: "3", sm: "3" },
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass:
                                        "form__input segment__select record__select-type error",
                                      class: {
                                        "form__input--invalid":
                                          schema.type.errors.length > 0,
                                      },
                                      attrs: {
                                        id: "schema-type",
                                        options: _vm.schemaTypes,
                                        disabled:
                                          !!_vm.recordId && schema.disabled,
                                        clearable: false,
                                        label: "name",
                                        placeholder: "Select",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleFormInput(
                                            _vm.formState,
                                            [schema.type]
                                          )
                                        },
                                      },
                                      model: {
                                        value: schema.type.value,
                                        callback: function ($$v) {
                                          _vm.$set(schema.type, "value", $$v)
                                        },
                                        expression: "schema.type.value",
                                      },
                                    }),
                                    schema.type.errors.length > 0
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            schema.type.errors,
                                            function (errors, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "alias__info--error",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "alias__info--error__message",
                                                    },
                                                    [_vm._v(_vm._s(errors))]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.recordsObject.recordChannel === 4
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "record__table-column--width designation__column",
                                        attrs: { lg: "3", md: "3", sm: "3" },
                                      },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "form__input segment__select record__select-type error",
                                          attrs: {
                                            options:
                                              _vm.designationOptions.filter(
                                                (opt) =>
                                                  !_vm.recordsObject.recordSchema.some(
                                                    (schema) =>
                                                      schema.designation
                                                        .value === opt
                                                  )
                                              ),
                                            disabled:
                                              !!_vm.recordId && schema.disabled,
                                            label: "text",
                                            placeholder: schema.disabled
                                              ? "null"
                                              : "Select",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleFormInput(
                                                _vm.formState
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "option",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "designation__select",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: data.icon,
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(data.text)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "selected-option",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "designation__select",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: data.icon,
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(data.text)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: schema.designation.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                schema.designation,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "schema.designation.value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "record__table-last__column",
                                    attrs: { lg: "2", md: "2", sm: "2" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "record__table-last__column-wrapper",
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          attrs: { disabled: !!_vm.recordId },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleFormInput(
                                                _vm.formState
                                              )
                                            },
                                          },
                                          model: {
                                            value: schema.required.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                schema.required,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "schema.required.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.recordsObject.recordSchema.length > 1 &&
                                    !schema.disabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "record__table--actions",
                                          },
                                          [
                                            _c("GjIcon", {
                                              staticClass: "draggable",
                                              attrs: {
                                                name: "Drag",
                                                size: "18",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                attrs: {
                                                  id: "remove-schema-row",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRow(index)
                                                  },
                                                },
                                              },
                                              [
                                                _c("GjIcon", {
                                                  attrs: {
                                                    name: "Close",
                                                    size: "18",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _c("div", {
                        class: {
                          "form__input--invalid":
                            _vm.recordsObject.recordSchemas.errors.length > 0,
                        },
                        staticStyle: { display: "hidden" },
                      }),
                      _vm.recordsObject.recordSchemas.errors.length > 0
                        ? _c(
                            "div",
                            { staticClass: "records-schema__general-error" },
                            _vm._l(
                              _vm.recordsObject.recordSchemas.errors,
                              function (error, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "alias__info--error",
                                  },
                                  [_vm._v(" " + _vm._s(error) + " ")]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.pipelineNotFound
                ? _c("div", { staticClass: "profiler__error-message" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M10.2517 5.147L3.65068 17.0287C2.91009 18.3618 3.87403 20 5.39899 20H18.6009C20.1259 20 21.0898 18.3618 20.3493 17.0287L13.7483 5.147C12.9863 3.77538 11.0137 3.77538 10.2517 5.147ZM13 9.00003C13 8.44775 12.5522 8.00003 12 8.00003C11.4477 8.00003 11 8.44775 11 9.00003V13C11 13.5523 11.4477 14 12 14C12.5522 14 13 13.5523 13 13V9.00003ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z",
                            fill: "#EF8C13",
                          },
                        }),
                      ]
                    ),
                    _c("div", [
                      _c("h5", [_vm._v(_vm._s(_vm.$t("Warning")))]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("data.tryRefreshingPage")) + "."),
                      ]),
                    ]),
                  ])
                : [
                    _vm.recordsObject.recordChannel === 4
                      ? _c(
                          "div",
                          { staticClass: "container__wrapper" },
                          [
                            _vm._m(0),
                            _c(
                              "b-col",
                              {
                                staticClass: "input__wrapper pl-0",
                                attrs: { lg: "6" },
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$t("data.pipelineIntegration")) +
                                      " *"
                                  ),
                                ]),
                                _c("v-select", {
                                  staticClass: "form__input segment__select",
                                  class: {
                                    "form__input--invalid":
                                      !!_vm.recordsObject.pipelineId.errors
                                        .length,
                                  },
                                  attrs: {
                                    options: _vm.profilerPipelines,
                                    disabled:
                                      !!_vm.recordId ||
                                      (_vm.parametersLoading &&
                                        !_vm.recordsObject.profilerPipeline),
                                    clearable: false,
                                    label: "title",
                                    loading: _vm.parametersLoading,
                                    placeholder: "Select Integration",
                                  },
                                  on: {
                                    input: (pipeline) => {
                                      _vm.getPipelineParameters(pipeline)
                                      _vm.handleFormInput(_vm.formState, [
                                        _vm.recordsObject.pipelineId,
                                      ])
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "spinner",
                                        fn: function ({ loading }) {
                                          return [
                                            loading
                                              ? _c("LoadingBar", {
                                                  attrs: {
                                                    classList: "small mr-1",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2878616075
                                  ),
                                  model: {
                                    value: _vm.recordsObject.profilerPipeline,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.recordsObject,
                                        "profilerPipeline",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "recordsObject.profilerPipeline",
                                  },
                                }),
                                _vm.recordsObject.pipelineId.errors.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.recordsObject.pipelineId.errors,
                                        function (error, i) {
                                          return _c(
                                            "span",
                                            {
                                              key: i,
                                              staticClass: "alias__info--error",
                                            },
                                            [_vm._v(" " + _vm._s(error) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.recordsObject.profilerPipeline &&
                            !_vm.parametersLoading
                              ? _vm._l(
                                  _vm.recordsObject.pipelineParameters,
                                  function (parameter) {
                                    return _c(
                                      "b-col",
                                      {
                                        key: parameter.name,
                                        staticClass: "input__wrapper pl-0",
                                        attrs: { lg: "6" },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(parameter.name)),
                                        ]),
                                        _c("b-form-input", {
                                          staticClass: "form__input",
                                          attrs: {
                                            placeholder: `Enter ${parameter.name}`,
                                            type: "text",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleFormInput(
                                                _vm.formState
                                              )
                                            },
                                          },
                                          model: {
                                            value: parameter.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                parameter,
                                                "value",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "parameter.value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.recordsObject.recordChannel === 4
                      ? _c("div", { staticClass: "container__wrapper" }, [
                          _c("div", { staticClass: "container__header" }, [
                            _c(
                              "h5",
                              { staticClass: "container__header-title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("audience.schedule")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "container__schedule" }, [
                            _c("div", { staticClass: "schedule__inputs" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "container__schedule__label-text",
                                },
                                [_vm._v(_vm._s(_vm.$t("data.runDataImport")))]
                              ),
                              _c(
                                "div",
                                { staticClass: "input__wrapper" },
                                [
                                  _c("v-select", {
                                    staticClass: "form__input",
                                    class: {
                                      "form__input--invalid":
                                        _vm.recordsObject.scheduleId.errors
                                          .length,
                                    },
                                    attrs: {
                                      id: "query-schedule",
                                      options: _vm.reprocessOperatorOptions,
                                      label: "text",
                                      clearable: false,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleFormInput(
                                          _vm.formState,
                                          [
                                            _vm.recordsObject.scheduleFrequency,
                                            _vm.recordsObject.scheduleId,
                                          ]
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dataImportSchedule.operator,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataImportSchedule,
                                          "operator",
                                          $$v
                                        )
                                      },
                                      expression: "dataImportSchedule.operator",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.dataImportSchedule.operator &&
                              _vm.dataImportSchedule.operator.text === "Every"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "input__wrapper",
                                      attrs: { id: "reprocessValue" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form__input-number",
                                        class: {
                                          "form__input--invalid":
                                            _vm.recordsObject.scheduleFrequency
                                              .errors.length,
                                        },
                                        attrs: {
                                          id: "schedule-number",
                                          placeholder: "2",
                                          type: "number",
                                          min: "1",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleFormInput(
                                              _vm.formState,
                                              [
                                                _vm.recordsObject
                                                  .scheduleFrequency,
                                                _vm.recordsObject.scheduleId,
                                              ]
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataImportSchedule.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataImportSchedule,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataImportSchedule.value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.dataImportSchedule.operator &&
                              _vm.dataImportSchedule.operator.text === "Every"
                                ? _c(
                                    "div",
                                    { staticClass: "input__wrapper" },
                                    [
                                      _c("v-select", {
                                        staticClass: "form__input",
                                        class: {
                                          "form__input--invalid":
                                            _vm.recordsObject.scheduleId.errors
                                              .length,
                                        },
                                        attrs: {
                                          id: "schedule-time-unit",
                                          placeholder: _vm.$t("Select"),
                                          options: _vm.reprocessTimeUnitOptions,
                                          label: "text",
                                          clearable: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleFormInput(
                                              _vm.formState,
                                              [
                                                _vm.recordsObject
                                                  .scheduleFrequency,
                                                _vm.recordsObject.scheduleId,
                                              ]
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.dataImportSchedule.timeUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataImportSchedule,
                                              "timeUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataImportSchedule.timeUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "container__schedule__info-text" },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("data.dataImportWillRun")) +
                                      " "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataImportSchedule.operator.text ===
                                          "Once"
                                          ? "only once"
                                          : +_vm.dataImportSchedule.value === 1
                                          ? `every ${_vm.dataImportSchedule.timeUnit.text.slice(
                                              0,
                                              -1
                                            )}`
                                          : `every ${_vm.dataImportSchedule.value} ${_vm.dataImportSchedule.timeUnit.text}`
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.recordsObject.scheduleId.errors.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.recordsObject.scheduleId.errors,
                                    function (error, i) {
                                      return _c(
                                        "span",
                                        {
                                          key: i,
                                          staticClass: "alias__info--error",
                                        },
                                        [_vm._v(" " + _vm._s(error) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.recordsObject.scheduleFrequency.errors.length >
                            0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.recordsObject.scheduleFrequency.errors,
                                    function (error, i) {
                                      return _c(
                                        "span",
                                        {
                                          key: i,
                                          staticClass: "alias__info--error",
                                        },
                                        [_vm._v(" " + _vm._s(error) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ],
              Object.keys(_vm.recordsObject.recordPreset).length
                ? _c("div", { staticClass: "container__wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "presets__container" },
                      [
                        _c("h5", { staticClass: "container__header-title" }, [
                          _vm._v(_vm._s(_vm.$t("data.presets"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "presets__container-info" },
                          [
                            _c("GjIcon", {
                              staticClass: "mr-50",
                              attrs: { name: "Info", size: "18" },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("data.presetInfo"))),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.recordsObject.recordPreset,
                          function (presetGroup, key, i) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle",
                                        value: "accordion-" + key,
                                        expression: "'accordion-' + key",
                                      },
                                    ],
                                    staticClass:
                                      "presets-container__expandable-heading",
                                  },
                                  [_vm._v(" " + _vm._s(key) + " ")]
                                ),
                                _c(
                                  "b-collapse",
                                  { attrs: { id: `accordion-${key}` } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "presets__wrapper" },
                                      [
                                        _vm._l(
                                          presetGroup,
                                          function (preset, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "presets__wrapper-content",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(_vm._s(preset.name)),
                                                ]),
                                                !_vm.recordId
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("data.type")
                                                        ) +
                                                          ": " +
                                                          _vm._s(preset.type)
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("data.type")
                                                        ) +
                                                          ": " +
                                                          _vm._s(
                                                            _vm.getSchemaType(
                                                              preset.type
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      id: `tooltip-${key}-${index}`,
                                                    },
                                                  },
                                                  [
                                                    _c("GjIcon", {
                                                      attrs: {
                                                        name: "Info",
                                                        size: "18",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      target: `tooltip-${key}-${index}`,
                                                      triggers: "hover",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          preset.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    id: `checkbox-${i}-${index}`,
                                                    selected: preset.selected,
                                                    disabled:
                                                      preset.isMandatory ||
                                                      (!!_vm.recordId &&
                                                        preset.disabled),
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkSelectAllState(
                                                        presetGroup,
                                                        i
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: preset.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        preset,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "preset.selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "presets-container__expandable-heading--extra",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("data.presetSelection")
                                                )
                                              ),
                                            ]),
                                            _c("b-form-checkbox", {
                                              staticClass:
                                                "records__checkbox-all",
                                              attrs: {
                                                disabled:
                                                  _vm.presetsState.isDisabled[
                                                    i
                                                  ],
                                                name: "selectAllCheckbox",
                                                "aria-describedby": `checkbox-${key}-${i}`,
                                                "aria-controls": `checkbox-${key}-${i}`,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkAll(
                                                    key,
                                                    _vm.presetsState.isChecked[
                                                      i
                                                    ]
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.presetsState.isChecked[i],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.presetsState.isChecked,
                                                    i,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "presetsState.isChecked[i]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c("Footer", {
                attrs: {
                  "is-loading": _vm.formState.isSubmitting,
                  submitted: _vm.formState.isSubmitting,
                  "cancel-id": `cancel-create-record`,
                  "create-id": `add-record`,
                  "edit-mode": !!_vm.recordId,
                  "disable-button": !_vm.isComplete || _vm.pipelineNotFound,
                },
                on: {
                  back: function ($event) {
                    return _vm.cancel()
                  },
                  create: function ($event) {
                    !_vm.recordId
                      ? _vm.createRecord(_vm.recordsObject)
                      : _vm.triggerSaveModal()
                  },
                },
              }),
            ],
            2
          ),
      _c("SaveChangesModal", {
        attrs: { type: _vm.$t("data.record") },
        on: {
          save: function ($event) {
            return _vm.createRecord(_vm.recordsObject)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container__header" }, [
      _c("h5", { staticClass: "container__header-title" }, [
        _vm._v("Integration"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }