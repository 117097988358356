import Vue from 'vue'
import VueRouter from 'vue-router'
import connections from './connections'
import exports from './exports'
import records from './records'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bisko/:organizationId/data',
    redirect: '/bisko/:organizationId/data/records'
  },
  ...records,
  ...connections,
  ...exports,
  {
    path: '/bisko/:organizationId/data/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
