<template>
  <section>
    <!-- Column: Name -->
    <span
      v-if="data.column.field === 'name'"
      class="dark-gray-color name__container"
    >
      <div class="name__wrapper">
        <IconHeading :id="data.row.id" :data="data" />
        <ReadMore
          :text="data.row.name"
          :to="data.row.canView ? viewLocation : null"
        />
      </div>
    </span>

    <!-- Column: Type -->
    <div v-else-if="data.column.field === 'type'">
      <span>{{ data.row.provider }}</span>
    </div>

    <!-- Column: Created Date -->
    <span
      v-else-if="data.column.field === 'insertedAt'"
      class="created-date__and-actions"
    >
      <span>{{ formatDate(data.row.insertedAt) }}</span>
    </span>
    <div v-if="data.column.field === 'actions'">
      <DropdownActions :items="dropdownActions" />
    </div>
  </section>
</template>

<script>
import { dataExports } from '@/api'
import store from '@/store'
import {
  DropdownActions,
  // utility components
  IconHeading,
  ReadMore,
  // utility functions
  formatDate
} from '@nodus/utilities-front'
import { getCurrentInstance, ref } from 'vue'

export default {
  name: 'ExportsData',
  components: {
    ReadMore,
    IconHeading,
    DropdownActions
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(false)

    const reactivateExport = async (id) => {
      await dataExports.reactivate(organizationId, id)
      await store.dispatch('exports/getExports', { id: organizationId })
    }

    const setIDtoDelete = (id) => {
      store.commit('exports/SET_ID_TO_DELETE', id)
    }

    const { id, status, canReactivate, canView, canDelete } = props.data.row

    const viewLocation = {
      name: 'export-view',
      params: {
        organizationId,
        exportId: id
      }
    }

    const dropdownActions = [
      {
        id: 'view-export',
        icon: 'IconparkPreviewOpen',
        text: vm.$t('View'),
        disabled: !canView,
        to: viewLocation
      },
      {
        id: 'reactivate-export',
        icon: 'Refresh',
        text: 'Reactivate',
        disabled: !canReactivate || status !== 'Failed',
        action: () => reactivateExport(id)
      },
      {
        id: 'delete-export',
        icon: 'Delete',
        text: 'Delete',
        type: 'danger',
        disabled: !canDelete || status !== 'Failed',
        modal: 'modal-small-delete',
        action: () => setIDtoDelete(id)
      }
    ]

    return {
      organizationId,
      isLoading,
      formatDate,
      reactivateExport,
      setIDtoDelete,
      dropdownActions,
      viewLocation
    }
  }
}
</script>

<style lang="scss">
.created-date__and-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 160px;

  button {
    color: #3c475a;
    background-color: transparent;
    border: none;
    outline: #ffffff;
    margin-left: 8px;

    & > svg {
      cursor: pointer;
      fill: #7483a1;
      width: 18px;
      height: 18px;
    }

    &[disabled] > svg {
      fill: #e0e5eb;
    }
  }
}
</style>
