var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "name"
      ? _c(
          "span",
          [
            _c("ReadMore", {
              attrs: {
                text: _vm.data.row.name,
                classes: _vm.data.row.canEdit
                  ? ["connections__name-column"]
                  : [],
                "click-handler": _vm.data.row.canEdit
                  ? () => {
                      _vm.setConnectionData(_vm.data.row.id)
                      _vm.$bvModal.show("modal-connections-create")
                    }
                  : () => {},
              },
            }),
          ],
          1
        )
      : _vm.data.column.field === "sharedData"
      ? _c(
          "span",
          _vm._l(_vm.data.row.sharedData, function (data, index) {
            return _c("span", { key: index }, [
              _c("span", [_vm._v(_vm._s(index === 1 ? `, ${data}` : data))]),
            ])
          }),
          0
        )
      : _vm.data.column.field === "insertDateTime"
      ? _c(
          "div",
          { staticClass: "block-elem" },
          [_c("DateColumn", { attrs: { data: _vm.data } })],
          1
        )
      : _vm.data.column.field === "status"
      ? _c("span", { staticClass: "status-and-actions" }, [
          _c(
            "div",
            { staticClass: "status-and-info" },
            [
              _c(
                "div",
                [
                  _c("b-form-checkbox", {
                    staticClass: "custom-control-primary",
                    attrs: {
                      id: `switch-status-${_vm.data.row.id}`,
                      disabled: !_vm.data.row.canEdit || _vm.isLoading,
                      checked: _vm.data.row.status ? true : false,
                      name: "check-button",
                      switch: "",
                    },
                    on: {
                      change: function ($event) {
                        _vm.data.row.canEdit
                          ? _vm.setStatus(
                              _vm.data.row.id,
                              _vm.data.row.status ? 0 : 1
                            )
                          : {}
                      },
                    },
                  }),
                ],
                1
              ),
              _c("InformationSidebar", {
                attrs: {
                  title: _vm.$t("data.connections.disable"),
                  content: _vm.$t("data.connections.disableInformation"),
                  code: _vm.data.row.id,
                },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "infoStatus"
      ? _c("span", [
          _vm.data.row.infoStatus === 1
            ? _c("span", { staticClass: "info__status" }, [
                _c("div", { staticClass: "dot dot--enabled" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("enabled")))]),
              ])
            : _c("span", { staticClass: "info__status" }, [
                _c("span", { staticClass: "dot dot--disabled" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("disabled")))]),
              ]),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }