import { render, staticRenderFns } from "./RecordView.vue?vue&type=template&id=06ff36bd&"
import script from "./RecordView.vue?vue&type=script&lang=js&"
export * from "./RecordView.vue?vue&type=script&lang=js&"
import style0 from "./RecordView.vue?vue&type=style&index=0&id=06ff36bd&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06ff36bd')) {
      api.createRecord('06ff36bd', component.options)
    } else {
      api.reload('06ff36bd', component.options)
    }
    module.hot.accept("./RecordView.vue?vue&type=template&id=06ff36bd&", function () {
      api.rerender('06ff36bd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/records/RecordView.vue"
export default component.exports