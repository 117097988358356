var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "view-screen__container" },
          [
            _vm.exportItem.providerError
              ? _c("div", { staticClass: "error__container" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "18",
                        viewBox: "0 0 18 18",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M8.99935 17.3333C13.6017 17.3333 17.3327 13.6023 17.3327 8.99996C17.3327 4.39759 13.6017 0.666626 8.99935 0.666626C4.39698 0.666626 0.666016 4.39759 0.666016 8.99996C0.666016 13.6023 4.39698 17.3333 8.99935 17.3333ZM9.83268 4.83329C9.83268 4.37306 9.45959 3.99996 8.99935 3.99996C8.53911 3.99996 8.16602 4.37306 8.16602 4.83329V8.99996C8.16602 9.4602 8.53911 9.83329 8.99935 9.83329C9.45959 9.83329 9.83268 9.4602 9.83268 8.99996V4.83329ZM8.99935 13.1666C9.45959 13.1666 9.83268 12.7935 9.83268 12.3333C9.83268 11.8731 9.45959 11.5 8.99935 11.5C8.53911 11.5 8.16602 11.8731 8.16602 12.3333C8.16602 12.7935 8.53911 13.1666 8.99935 13.1666Z",
                          fill: "#E34850",
                        },
                      }),
                    ]
                  ),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.exportItem.providerError)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "container__wrapper container__wrapper--height" },
              [
                _vm.exportItem
                  ? _c("div", { staticClass: "export-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "export-content-header integration__header-container-view",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "export-content-header__image",
                              class: {
                                "failed-export__image":
                                  _vm.exportItem.providerError,
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.exportItem.imageUrl },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "export-content-header__text" },
                            [
                              _c(
                                "span",
                                { staticClass: "integration__header-name" },
                                [_vm._v(_vm._s(_vm.exportItem.providerName))]
                              ),
                              _c(
                                "span",
                                { staticClass: "integration__description" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("data.exports.integrationWith")
                                      ) +
                                      " " +
                                      _vm._s(_vm.exportItem.providerName) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "export-content-body" }, [
                        _c(
                          "div",
                          { staticClass: "export-content-inputs" },
                          [
                            _c("div", { staticClass: "item__container" }, [
                              _c("span", { staticClass: "template__text" }, [
                                _vm._v(_vm._s(_vm.$t("Name")) + ":"),
                              ]),
                              _c(
                                "span",
                                { staticClass: "dynamic-data__text" },
                                [_vm._v(_vm._s(_vm.exportItem.name))]
                              ),
                            ]),
                            _vm._l(
                              _vm.exportItem.parameters,
                              function (parameter, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "item__container" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "template__text" },
                                        [_vm._v(_vm._s(parameter.name) + ":")]
                                      ),
                                      parameter.name === "Credentials"
                                        ? _c(
                                            "div",
                                            [
                                              _c("ReadMore", {
                                                attrs: {
                                                  classes: [
                                                    "dynamic-data__text",
                                                    "credentials__text",
                                                  ],
                                                  text: parameter.value,
                                                  "number-of-chars": 1000,
                                                  "parse-json": "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("ReadMore", {
                                                attrs: {
                                                  text: parameter.value,
                                                  "number-of-chars": 120,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("Footer", {
              attrs: { "view-mode": "" },
              on: {
                back: function ($event) {
                  return _vm.cancel()
                },
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }