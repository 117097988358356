var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "view-screen__container" },
          [
            _c(
              "div",
              { staticClass: "record-view__container" },
              [
                _c(
                  "div",
                  { staticClass: "basic-information__container" },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "container__title container__title--margin",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("data.basicInfo")) + " ")]
                    ),
                    _vm.record.canEdit && !_vm.pipelineNotFound
                      ? _c(
                          "b-link",
                          {
                            staticClass: "edit__button",
                            attrs: {
                              to: {
                                name: "record-edit",
                                params: {
                                  organizationId: _vm.organizationId,
                                  recordId: _vm.recordId,
                                },
                              },
                            },
                          },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Edit", size: "18" },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.$t("Edit")))]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "item__container" }, [
                      _c("span", { staticClass: "template__text" }, [
                        _vm._v(_vm._s(_vm.$t("Name"))),
                      ]),
                      _c("span", { staticClass: "dynamic-data__text" }, [
                        _vm._v(_vm._s(_vm.record.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item__container" }, [
                      _c("span", { staticClass: "template__text" }, [
                        _vm._v(_vm._s(_vm.$t("data.alias"))),
                      ]),
                      _c("span", { staticClass: "dynamic-data__text" }, [
                        _vm._v(_vm._s(_vm.record.alias)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "item__container" },
                      [
                        _c("span", { staticClass: "template__text" }, [
                          _vm._v(_vm._s(_vm.$t("Description"))),
                        ]),
                        _c("ReadMore", {
                          attrs: {
                            text: _vm.record.description,
                            mode: "view",
                            classes: ["dynamic-data__text"],
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item__container item__container--center",
                      },
                      [
                        _c("span", { staticClass: "template__text" }, [
                          _vm._v(_vm._s(_vm.$t("data.recordType"))),
                        ]),
                        _c("GjIcon", {
                          staticClass: "icon__margin",
                          attrs: {
                            hidden: _vm.record.recordChannel !== 1,
                            name: "Webpage",
                          },
                        }),
                        _c("GjIcon", {
                          staticClass: "icon__margin--mobile",
                          attrs: {
                            hidden: _vm.record.recordChannel !== 2,
                            name: "Mobile",
                          },
                        }),
                        _c("GjIcon", {
                          staticClass: "icon__margin",
                          attrs: {
                            hidden: _vm.record.recordChannel !== 3,
                            name: "Api",
                          },
                        }),
                        _c("img", {
                          staticClass: "icon__margin",
                          attrs: {
                            hidden: _vm.record.recordChannel !== 4,
                            src: require("@/assets/svgs/records/channel-profiler.svg"),
                          },
                        }),
                        _c("span", { staticClass: "dynamic-data__text" }, [
                          _vm._v(
                            _vm._s(_vm.getRecordType(_vm.record.recordChannel))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "view-schemas__container" },
                  [
                    _c("h5", { staticClass: "container__title" }, [
                      _vm._v(_vm._s(_vm.$t("data.schema"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "record__table record__table--heading" },
                      [
                        _c("b-col", { staticClass: "record__table-column" }, [
                          _c("span", { staticClass: "record__table-header" }, [
                            _vm._v(_vm._s(_vm.$t("data.fieldType"))),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          {
                            staticClass: "record__table-column",
                            attrs: { cols: "2", sm: "3" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "record__table-header" },
                              [_vm._v(_vm._s(_vm.$t("Name")))]
                            ),
                          ]
                        ),
                        _c("b-col", { staticClass: "record__table-column" }, [
                          _c("span", { staticClass: "record__table-header" }, [
                            _vm._v(_vm._s(_vm.$t("data.type"))),
                          ]),
                        ]),
                        _vm.record.recordChannel === 4
                          ? _c(
                              "b-col",
                              { staticClass: "record__table-column" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "record__table-header" },
                                  [_vm._v(_vm._s(_vm.$t("data.designation")))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("b-col", { staticClass: "record__table-column" }, [
                          _c("span", { staticClass: "record__table-header" }, [
                            _vm._v(_vm._s(_vm.$t("Required"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.record.allSchemas, function (schema, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "record__table" },
                        [
                          _c("b-col", { staticClass: "record__table-column" }, [
                            _c("span", { staticClass: "record__table-item" }, [
                              _vm._v(_vm._s(schema.fieldType)),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "record__table-column",
                              attrs: { cols: "2", sm: "3" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "record__table-item" },
                                [_vm._v(_vm._s(schema.name))]
                              ),
                            ]
                          ),
                          _c("b-col", { staticClass: "record__table-column" }, [
                            _c("span", { staticClass: "record__table-item" }, [
                              _vm._v(_vm._s(_vm.getSchemaType(schema.type))),
                            ]),
                          ]),
                          _vm.record.recordChannel === 4
                            ? _c(
                                "b-col",
                                { staticClass: "record__table-column" },
                                [
                                  schema.designation
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "record__table-designation-item",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.getDesignation(
                                                schema.designation
                                              ).icon,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDesignation(
                                                  schema.designation
                                                ).text
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c("b-col", { staticClass: "record__table-column" }, [
                            _c(
                              "div",
                              { staticClass: "record__table-designation-item" },
                              [
                                schema.required
                                  ? _c("img", {
                                      staticClass: "record__table-item",
                                      attrs: {
                                        src: require("@/assets/svgs/checkIcon.svg"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _vm.pipelineNotFound
                  ? _c("div", { staticClass: "profiler__error-message" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "24",
                            height: "24",
                            viewBox: "0 0 24 24",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M10.2517 5.147L3.65068 17.0287C2.91009 18.3618 3.87403 20 5.39899 20H18.6009C20.1259 20 21.0898 18.3618 20.3493 17.0287L13.7483 5.147C12.9863 3.77538 11.0137 3.77538 10.2517 5.147ZM13 9.00003C13 8.44775 12.5522 8.00003 12 8.00003C11.4477 8.00003 11 8.44775 11 9.00003V13C11 13.5523 11.4477 14 12 14C12.5522 14 13 13.5523 13 13V9.00003ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z",
                              fill: "#EF8C13",
                            },
                          }),
                        ]
                      ),
                      _c("div", [
                        _c("h5", [_vm._v(_vm._s(_vm.$t("Warning")))]),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("data.tryRefreshingPage")) + "."
                          ),
                        ]),
                      ]),
                    ])
                  : _vm.getRecordType(_vm.record.recordChannel) === "Profiler"
                  ? [
                      _c(
                        "div",
                        { staticClass: "profiler-section__container" },
                        [
                          _c("h5", { staticClass: "container__title" }, [
                            _vm._v(_vm._s(_vm.$t("Integration"))),
                          ]),
                          _c("div", { staticClass: "item__container" }, [
                            _c("span", { staticClass: "template__text" }, [
                              _vm._v(
                                _vm._s(_vm.$t("data.pipelineIntegration"))
                              ),
                            ]),
                            _c("span", { staticClass: "dynamic-data__text" }, [
                              _vm._v(_vm._s(_vm.record.pipeline)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "profiler-section__container" },
                        [
                          _c("h5", { staticClass: "container__title" }, [
                            _vm._v(_vm._s(_vm.$t("audience.schedule"))),
                          ]),
                          _c("div", { staticClass: "item__container" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "template__text template__text--no-break",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("data.dataImportWillRun")) + " "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.record.scheduling)),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("ImportExecutions", {
                        attrs: {
                          "import-executions": _vm.record.importExecutions,
                        },
                        on: { "update-table": _vm.getImportRuns },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("Footer", {
              attrs: { "view-mode": "" },
              on: {
                back: function ($event) {
                  return _vm.cancel()
                },
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }