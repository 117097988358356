<template>
  <section>
    <div class="table-list__header">
      <primary-create-button
        id="create-export"
        :name="$t('data.exports.enableExport')"
        :is-loading="isLoading"
        :can-add="canCreate"
        :click-handler="() => $bvModal.show('modal-lg-create-integration')"
      />
      <SearchInputField id="search-exports" :data="exports" />
    </div>
    <div class="table__wrapper">
      <CustomTable
        :data="exports"
        type-of-table="exports"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canCreate,
          action: () => $bvModal.show('modal-lg-create-integration')
        }"
        @update-table="getData"
      >
        <template #row-data="{ data }">
          <ExportsRow :data="data" />
        </template>
      </CustomTable>
    </div>

    <CustomModal
      :select-options="providers"
      :modal-title="$t('data.exports.newExport')"
      :modal-text="$t('data.exports.chooseThirdParty')"
      type="integration"
      :documentation-link="`${whitelabel.documentationUrl}/intro#introduction-to-data-exports`"
      :documentation-text="$t('data.exports.documentationLink')"
      @on-create="createExportForm"
    />

    <DeleteModal
      :title="$t('data.exports.deleteExport')"
      :type="$t('data.exports.export')"
      @delete="triggerDelete"
    />
  </section>
</template>

<script>
import { dataExports } from '@/api'
import ExportsRow from '@/components/table-data/ExportsRow.vue'
import router from '@/router'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  CustomModal,
  CustomTable,
  DeleteModal,
  PrimaryCreateButton,
  SearchInputField,
  // utility functions
  handleLastRowDelete
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'Exports',
  components: {
    CustomTable,
    SearchInputField,
    PrimaryCreateButton,
    DeleteModal,
    CustomModal,
    ExportsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const isLoading = ref(true)

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('exports/getExports', { id: organizationId })
      isLoading.value = false
    }

    onMounted(async () => {
      store.dispatch('exports/getProviders', { id: organizationId })
      await store.dispatch('exports/getExports', { id: organizationId })
      isLoading.value = false
    })

    const exports = computed(() => store.getters['exports/exports'])
    const providers = computed(() => store.getters['exports/providers'])
    const IDtoDelete = computed(() => store.getters['exports/IDtoDelete'])

    const canCreate = computed(() => store.getters['exports/canCreate'])

    const createExportForm = (selected) => {
      router.push({
        name: 'export-create',
        params: { organizationId, providerId: selected }
      })
    }

    const triggerDelete = async () => {
      handleLastRowDelete(exports.value, store, 'exports/SET_TABLE_PAGE')
      await dataExports.remove(organizationId, IDtoDelete.value)
      getData()
    }

    return {
      exports,
      isLoading,
      canCreate,
      createExportForm,
      getData,
      providers,
      triggerDelete,
      whitelabel
    }
  }
}
</script>
