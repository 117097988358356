<template>
  <section>
    <div v-if="!isLoading" class="view-screen__container">
      <div v-if="exportItem.providerError" class="error__container">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.99935 17.3333C13.6017 17.3333 17.3327 13.6023 17.3327 8.99996C17.3327 4.39759 13.6017 0.666626 8.99935 0.666626C4.39698 0.666626 0.666016 4.39759 0.666016 8.99996C0.666016 13.6023 4.39698 17.3333 8.99935 17.3333ZM9.83268 4.83329C9.83268 4.37306 9.45959 3.99996 8.99935 3.99996C8.53911 3.99996 8.16602 4.37306 8.16602 4.83329V8.99996C8.16602 9.4602 8.53911 9.83329 8.99935 9.83329C9.45959 9.83329 9.83268 9.4602 9.83268 8.99996V4.83329ZM8.99935 13.1666C9.45959 13.1666 9.83268 12.7935 9.83268 12.3333C9.83268 11.8731 9.45959 11.5 8.99935 11.5C8.53911 11.5 8.16602 11.8731 8.16602 12.3333C8.16602 12.7935 8.53911 13.1666 8.99935 13.1666Z"
            fill="#E34850"
          />
        </svg>
        <span class="error-message">{{ exportItem.providerError }}</span>
      </div>
      <div class="container__wrapper container__wrapper--height">
        <div v-if="exportItem" class="export-content">
          <div class="export-content-header integration__header-container-view">
            <div
              class="export-content-header__image"
              :class="{ 'failed-export__image': exportItem.providerError }"
            >
              <img :src="exportItem.imageUrl" />
            </div>
            <div class="export-content-header__text">
              <span class="integration__header-name">{{
                exportItem.providerName
              }}</span>
              <span class="integration__description">
                {{ $t('data.exports.integrationWith') }}
                {{ exportItem.providerName }}
              </span>
            </div>
          </div>
          <div class="export-content-body">
            <div class="export-content-inputs">
              <div class="item__container">
                <span class="template__text">{{ $t('Name') }}:</span>
                <span class="dynamic-data__text">{{ exportItem.name }}</span>
              </div>
              <div
                v-for="(parameter, index) in exportItem.parameters"
                :key="index"
              >
                <div class="item__container">
                  <span class="template__text">{{ parameter.name }}:</span>
                  <div v-if="parameter.name === 'Credentials'">
                    <ReadMore
                      :classes="['dynamic-data__text', 'credentials__text']"
                      :text="parameter.value"
                      :number-of-chars="1000"
                      parse-json
                    />
                  </div>
                  <div v-else>
                    <ReadMore :text="parameter.value" :number-of-chars="120" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer view-mode @back="cancel()" />
    </div>
    <div v-else class="loading__container">
      <LoadingBar />
    </div>
  </section>
</template>

<script>
import store from '@/store'
import {
  // utility components
  Footer,
  LoadingBar,
  ReadMore
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'ExportView',
  components: {
    LoadingBar,
    ReadMore,
    Footer
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, exportId } = vm.$route.params

    const isLoading = ref(false)

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('exports/getExport', {
        id: organizationId,
        exportId
      })
      isLoading.value = false
    })

    const exportItem = computed(() => store.getters['exports/export'])

    const formatCredentials = computed(() => {
      try {
        const credentialParam = exportItem.value.parameters.find(
          (param) => param.name === 'Credentials'
        )
        return JSON.parse(credentialParam.value)
      } catch (e) {
        return false
      }
    })

    const cancel = () => {
      vm.$router.push({ name: 'exports', params: { organizationId } })
    }

    return {
      organizationId,
      exportItem,
      isLoading,
      formatCredentials,
      cancel
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/views/exports/index.scss';
@import '@/assets/scss/views/exports/export-view.scss';
</style>
