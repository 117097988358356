var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.state.isLoading
    ? _c(
        "section",
        { staticClass: "form__wrapper" },
        [
          _c("div", { staticClass: "container__wrapper" }, [
            _c("div", [
              _c("h5", { staticClass: "container__header-title" }, [
                _vm._v(_vm._s(_vm.$t("configuration"))),
              ]),
            ]),
            _c("div", { staticClass: "export-content" }, [
              _c("div", { staticClass: "export-content-header" }, [
                _c("div", { staticClass: "export-content-header__image" }, [
                  _c("img", { attrs: { src: _vm.exportParameters.imageUrl } }),
                ]),
                _c("div", { staticClass: "export-content-header__text" }, [
                  _c("span", { staticClass: "integration__header-name" }, [
                    _vm._v(_vm._s(_vm.exportParameters.providerName)),
                  ]),
                  _c("span", { staticClass: "integration__description" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("data.exports.exportDescription")) +
                        " " +
                        _vm._s(_vm.exportParameters.providerName) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "export-content-body" }, [
                !!_vm.exportParameters.name
                  ? _c(
                      "div",
                      { staticClass: "export-content-inputs" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "input__wrapper",
                            attrs: { lg: "12" },
                          },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                            ]),
                            _c("b-form-input", {
                              staticClass: "form__input",
                              class: {
                                "form__input--invalid":
                                  _vm.exportParameters.name.errors.length > 0,
                              },
                              attrs: {
                                id: "integration-name",
                                placeholder: _vm.$t("audience.enterName"),
                                required: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.handleFormInput(_vm.state, [
                                    _vm.exportParameters.name,
                                  ])
                                },
                              },
                              model: {
                                value: _vm.exportParameters.name.value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.exportParameters.name,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "exportParameters.name.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.exportParameters.name.errors,
                          function (error, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "export-content-inputs__errors",
                              },
                              [_vm._v(" " + _vm._s(error) + " ")]
                            )
                          }
                        ),
                        _vm._l(
                          _vm.exportParameters.parameters,
                          function (parameter) {
                            return _c(
                              "div",
                              { key: parameter.parameterId },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "input__wrapper",
                                    attrs: { lg: "12" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          parameter.parameterName +
                                            (parameter.required ? " *" : "")
                                        )
                                      ),
                                    ]),
                                    parameter.parameterName === "Credentials"
                                      ? _c("b-form-textarea", {
                                          attrs: {
                                            id: "export-credentials",
                                            rows: "10",
                                            "max-rows": "10",
                                            "no-resize": "",
                                            size: "sm",
                                          },
                                          model: {
                                            value: parameter.value.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                parameter.value,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "parameter.value.value",
                                          },
                                        })
                                      : _c("b-form-input", {
                                          staticClass: "form__input",
                                          class: {
                                            "form__input--invalid":
                                              parameter.value.errors.length > 0,
                                          },
                                          attrs: {
                                            id: `exportParameters-${parameter.parameterName}`,
                                            placeholder:
                                              "Enter " +
                                              parameter.parameterName +
                                              "...",
                                            required: parameter.required,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleFormInput(
                                                _vm.state,
                                                [parameter.value]
                                              )
                                            },
                                          },
                                          model: {
                                            value: parameter.value.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                parameter.value,
                                                "value",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "parameter.value.value",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  parameter.value.errors,
                                  function (error, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "export-content-inputs__errors",
                                      },
                                      [_vm._v(" " + _vm._s(error) + " ")]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("Footer", {
            attrs: {
              "is-loading": _vm.state.isLoading,
              submitted: _vm.state.submitted,
              "cancel-id": `cancel-create-export`,
              "create-id": `add-export`,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                return _vm.saveExport()
              },
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }