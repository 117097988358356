import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'

export default {
  state: {
    sharedConnections: {
      columns: [
        {
          label: 'Connection Name',
          field: 'name',
          width: '20%',
          thClass: 'name-column'
        },
        {
          label: 'Organization Name',
          field: 'organizationName',
          width: '20%'
        },
        {
          label: 'Shared Data',
          field: 'sharedData',
          width: '15%',
          thClass: 'not-sortable-headers',
          sortable: false
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          width: '35%'
        },
        {
          label: 'Status',
          field: 'infoStatus',
          width: '10%',
          thClass: 'not-sortable-headers',
          sortable: false
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    }
  },
  getters: {
    sharedConnections: (state) => state.sharedConnections
  },
  actions: {
    async getSharedConnections({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.sharedConnections.searchTerm,
        state.sharedConnections.page,
        state.sharedConnections.perPage,
        state.sharedConnections.orderField,
        state.sharedConnections.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/sharedConnections?${queryParamString}`
      )

      commit(
        'SET_SHARED_CONNECTIONS',
        destructJSONObject(response, 'connections')
      )
      commit(
        'SET_TOTAL_SHARED_RECORDS',
        destructJSONObject(response, 'totalCount')
      )
    }
  },
  mutations: {
    SET_SHARED_CONNECTIONS(state, connections) {
      state.sharedConnections.rows = connections
    },
    SET_TOTAL_SHARED_RECORDS(state, totalRecords) {
      state.sharedConnections.totalRecords = totalRecords
    }
  }
}
