var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "connections__container" },
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            {
              attrs: {
                id: "my-connections",
                title: "My Connections",
                active: "",
              },
            },
            [
              _c("div", { staticClass: "connections-list__container" }, [
                _c(
                  "div",
                  { staticClass: "table-list__header" },
                  [
                    _c("primary-create-button", {
                      attrs: {
                        id: "create-connection",
                        name: _vm.$t("data.connections.addConnection"),
                        "can-add": _vm.canCreate,
                        "is-loading": _vm.isLoading,
                        "click-handler": _vm.openModal,
                      },
                    }),
                    _c("SearchInputField", {
                      attrs: {
                        id: "search-my-connections",
                        data: _vm.myConnections,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table__wrapper" },
                  [
                    _c("CustomTable", {
                      attrs: {
                        data: _vm.myConnections,
                        "type-of-table": "connections",
                        "is-pagination-enabled": true,
                        "is-loading": _vm.isLoading,
                        "empty-state-action": {
                          canAdd: _vm.canCreate,
                          action: _vm.openModal,
                        },
                      },
                      on: { "update-table": _vm.updateMyConnectionsTable },
                      scopedSlots: _vm._u([
                        {
                          key: "row-data",
                          fn: function ({ data }) {
                            return [
                              _c("ConnectionsRow", { attrs: { data: data } }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-tab",
            {
              attrs: { id: "shared-connections", title: "Shared Connections" },
            },
            [
              _c("div", { staticClass: "connections-list__container" }, [
                _c("div", { staticClass: "connections-list__header-row" }, [
                  _c(
                    "div",
                    { staticClass: "shared__connections-header" },
                    [
                      _c("SearchInputField", {
                        attrs: {
                          id: "search-shared-connections",
                          data: _vm.sharedConnections,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table__wrapper" },
                    [
                      _c("CustomTable", {
                        attrs: {
                          data: _vm.sharedConnections,
                          "type-of-table": "shared-connections",
                          "is-pagination-enabled": true,
                          "is-loading": _vm.isSharedLoading,
                        },
                        on: {
                          "update-table": _vm.updateSharedConnectionsTable,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "row-data",
                            fn: function ({ data }) {
                              return [
                                _c("ConnectionsRow", { attrs: { data: data } }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "connectionModal",
          attrs: {
            id: "modal-connections-create",
            "ok-title":
              !_vm.IDtoEdit && !_vm.isModalLoading
                ? _vm.$t("Create")
                : _vm.$t("Save"),
            "ok-disabled": _vm.isModalLoading,
            "cancel-title": _vm.$t("Cancel"),
            "cancel-variant": "outline-secondary",
            "modal-class": "connection__modal",
            centered: "",
            size: "md",
            title:
              !_vm.IDtoEdit && !_vm.isModalLoading
                ? _vm.$t("data.connections.createTitle")
                : _vm.$t("data.connections.editTitle"),
            "no-enforce-focus": "",
            "no-close-on-backdrop": "",
          },
          on: {
            ok: _vm.createConnection,
            cancel: _vm.onModalClose,
            close: _vm.onModalClose,
          },
        },
        [
          !_vm.isModalLoading
            ? _c("div", { staticClass: "connection__modal-content" }, [
                _c("p", { staticClass: "connection__modal-information" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("data.connections.connectionInformation")) +
                      " " +
                      _vm._s(_vm.$t("data.connections.documentationLink")) +
                      " "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-connections`,
                        target: "_blank",
                      },
                    },
                    [_vm._v("documentation")]
                  ),
                  _vm._v(". "),
                ]),
                _c(
                  "div",
                  { staticClass: "connection__modal-input__wrapper" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("data.connections.connectionName")) + " *"
                      ),
                    ]),
                    _c("b-form-input", {
                      staticClass: "connection__modal-form__input",
                      class: {
                        "connection__modal-form__input--invalid":
                          _vm.connection.connectionName.errors.length > 0,
                      },
                      attrs: {
                        id: "connection-name",
                        disabled: !!_vm.IDtoEdit,
                        placeholder: _vm.$t(
                          "data.connections.enterConnectionName"
                        ),
                      },
                      on: {
                        input: function ($event) {
                          _vm.handleFormInput((_vm.form = null), [
                            _vm.connection.connectionName,
                          ])
                        },
                      },
                      model: {
                        value: _vm.connection.connectionName.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.connection.connectionName,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "connection.connectionName.value",
                      },
                    }),
                    _vm._l(
                      _vm.connection.connectionName.errors,
                      function (error, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "connection__modal-error-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "connection__modal-input__wrapper" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("data.connections.organizationAlias")) +
                          " *"
                      ),
                    ]),
                    _c("b-form-input", {
                      staticClass: "connection__modal-form__input",
                      class: {
                        "connection__modal-form__input--invalid":
                          _vm.connection.organizationAlias.errors.length > 0,
                      },
                      attrs: {
                        id: "connection-alias",
                        disabled: !!_vm.IDtoEdit,
                        placeholder: _vm.$t(
                          "data.connections.enterOrganizationAlias"
                        ),
                      },
                      on: {
                        input: function ($event) {
                          _vm.handleFormInput((_vm.form = null), [
                            _vm.connection.organizationAlias,
                          ])
                        },
                      },
                      model: {
                        value: _vm.connection.organizationAlias.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.connection.organizationAlias,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "connection.organizationAlias.value",
                      },
                    }),
                    _vm._l(
                      _vm.connection.organizationAlias.errors,
                      function (error, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "connection__modal-error-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "mt-50" },
                  [
                    _c(
                      "div",
                      { staticClass: "connection__checkbox-container" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "share-events", name: "share-events" },
                            on: {
                              input: function ($event) {
                                _vm.handleFormInput((_vm.form = null), [
                                  _vm.connection.model,
                                ])
                              },
                            },
                            model: {
                              value: _vm.connection.shareEvents,
                              callback: function ($$v) {
                                _vm.$set(_vm.connection, "shareEvents", $$v)
                              },
                              expression: "connection.shareEvents",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("data.connections.shareEvents")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          { attrs: { id: "events-informations" } },
                          [
                            _c("GjIcon", {
                              staticClass: "ml-50",
                              attrs: { name: "Info", size: "18" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "events-informations",
                              triggers: "hover",
                              placement: "bottom",
                              "custom-class": "connection__modal-tooltip-info",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("data.connections.eventsInformations")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "connection__checkbox-container" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              id: "share-records",
                              name: "share-records",
                            },
                            on: {
                              input: function ($event) {
                                _vm.handleFormInput((_vm.form = null), [
                                  _vm.connection.model,
                                ])
                              },
                            },
                            model: {
                              value: _vm.connection.shareRecords,
                              callback: function ($$v) {
                                _vm.$set(_vm.connection, "shareRecords", $$v)
                              },
                              expression: "connection.shareRecords",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("data.connections.shareRecords")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          { attrs: { id: "records-informations" } },
                          [
                            _c("GjIcon", {
                              staticClass: "ml-50",
                              attrs: { name: "Info", size: "18" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "records-informations",
                              triggers: "hover",
                              placement: "bottom",
                              "custom-class": "connection__modal-tooltip-info",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("data.connections.recordsInformations")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.connection.model.errors,
                      function (error, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "connection__modal-error-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ])
            : _c("div", { staticClass: "connection__modal-body--height" }, [
                _c(
                  "div",
                  { staticClass: "loading__container" },
                  [_c("LoadingBar")],
                  1
                ),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }