import axios from "@/libs/axios";
import { API_BASE_URL } from "@/utils/constants";

const records = {
  createRecord: (organizationId = "", data = []) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/records`, data),
  updateRecord: (organizationId = "", recordId = "", data = []) =>
    axios.patch(
      `${API_BASE_URL}/api/v1/${organizationId}/records/${recordId}`,
      data
    ),
  setStatus: (organizationId = "", alias = "", status = 0) =>
    axios.put(
      `${API_BASE_URL}/api/v1/${organizationId}/records/${alias}/activate/${status}`
    ),
  validateAlias: (organizationId = "", alias = []) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/records/validate`, {
      alias,
    }),
  uploadFile: (__, data = "") =>
    axios.post(`/summary`, data, {
      baseURL: "https://record-schema-generator.gjirafa.ai",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }),
};

const connections = {
  addConnection: (organizationId = "", data = []) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/connections`, {
      connectionName: data.connectionName.value,
      organizationAlias: data.organizationAlias.value,
      shareRecords: data.shareRecords,
      shareEvents: data.shareEvents,
    }),
  setStatus: (organizationId = "", id = "", status = 0) =>
    axios.put(
      `${API_BASE_URL}/api/v1/${organizationId}/connections/${id}/activate/${status}`
    ),
  updateConnection: (organizationId = "", connectionId = "", data = []) =>
    axios.patch(
      `${API_BASE_URL}/api/v1/${organizationId}/connections/${connectionId}`,
      {
        connectionName: data.connectionName.value,
        organizationAlias: data.organizationAlias.value,
        shareRecords: data.shareRecords,
        shareEvents: data.shareEvents,
      }
    ),
};

const dataExports = {
  addExport: (organizationId = "", data = []) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/exports`, data),
  remove: (organizationId = "", id = "") =>
    axios.delete(`${API_BASE_URL}/api/v1/${organizationId}/exports/${id}`),
  reactivate: (organizationId = "", id = "") =>
    axios.patch(
      `${API_BASE_URL}/api/v1/${organizationId}/exports/${id}/reactivate`
    ),
};

export { records, connections, dataExports };
