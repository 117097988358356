import Vue from "vue";
import Vuex from "vuex";

import connections from "./connections";
import exports from "./exports";
import records from "./records";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    records,
    connections,
    exports,
  },
});
