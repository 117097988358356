<template>
  <section>
    <!-- Column: Name -->
    <span v-if="data.column.field === 'name'" class="dark-gray-color">
      <div class="name__wrapper">
        <ReadMore :text="data.row.name" :to="viewLocation" />
      </div>
    </span>

    <!-- Column: Alias -->
    <span v-else-if="data.column.field === 'alias'">
      <ReadMore :text="data.row.alias" />
    </span>

    <!-- Column: Create and Update Date -->
    <div v-else-if="data.column.field === 'insertDateTime'">
      <DateColumn :data="data"></DateColumn>
    </div>

    <!-- Column: status -->
    <span v-else-if="data.column.field === 'status'" class="status-and-actions">
      <div class="status-and-info">
        <b-form-checkbox
          :id="`switch-status-${data.row.alias}`"
          :disabled="!data.row.canEnable || isLoading"
          :checked="!!data.row.status"
          class="custom-control-primary"
          name="check-button"
          switch
          @change="
            data.row.canEnable
              ? setStatus(data.row.alias, data.row.status ? 0 : 1)
              : {}
          "
        />
        <InformationSidebar
          :title="$t('data.recordsDisable')"
          :content="$t('data.recordsDisableInformation')"
          :code="data.row.alias"
        />
      </div>
      <div class="data__actions">
        <DropdownActions :items="dropdownActions" />
      </div>
    </span>

    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </section>
</template>

<script>
import { records } from '@/api'
import store from '@/store'
import {
  DateColumn,
  DropdownActions,
  // utility components
  InformationSidebar,
  ReadMore,
  // utility functions
  formatDate
} from '@nodus/utilities-front'
import { BFormCheckbox } from 'bootstrap-vue'
import { getCurrentInstance, ref } from 'vue'

export default {
  name: 'RecordsData',
  components: {
    BFormCheckbox,
    ReadMore,
    InformationSidebar,
    DateColumn,
    DropdownActions
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const isLoading = ref(false)

    const setStatus = async (alias, status) => {
      isLoading.value = true
      await records.setStatus(organizationId, alias, status)
      store.dispatch('records/getRecords', { id: organizationId }).then(() => {
        isLoading.value = false
      })
    }

    const setRecordData = async (data, id) => {
      store.commit('records/SET_IS_MODAL_LOADING', true)
      store.commit('records/SET_RECORD', data)
      store.dispatch('records/getTrackingCode', { id, recordId: data.id })
    }

    const { recordType, canEdit, id } = props.data.row

    const viewLocation = {
      name: 'record-view',
      params: {
        organizationId: organizationId,
        recordId: id
      }
    }

    const editLocation = {
      name: 'record-edit',
      params: {
        organizationId: organizationId,
        recordId: id
      }
    }

    const dropdownActions = [
      {
        id: 'view-record',
        icon: 'IconparkPreviewOpen',
        text: vm.$t('View'),
        to: viewLocation
      },
      {
        id: 'record-tracking-code',
        icon: 'Embed',
        text: 'Tracking Code',
        disabled: !(recordType === 'Mobile' || recordType === 'Web'),
        modal:
          recordType === 'Mobile'
            ? 'modal-tracking-code-Mobile'
            : 'modal-tracking-code-Web',
        action: () => setRecordData(props.data.row, organizationId)
      },
      {
        id: 'edit-record',
        icon: 'Edit',
        text: vm.$t('Edit'),
        disabled: !canEdit,
        to: editLocation
      }
    ]

    return {
      organizationId,
      formatDate,
      setStatus,
      setRecordData,
      isLoading,
      dropdownActions,
      viewLocation
    }
  }
}
</script>

<style lang="scss">
.status-and-actions {
  &__view-record {
    cursor: pointer;
  }
}

.img {
  margin-right: 15px !important;
}
</style>
