var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("primary-create-button", {
            attrs: {
              id: "create-export",
              name: _vm.$t("data.exports.enableExport"),
              "is-loading": _vm.isLoading,
              "can-add": _vm.canCreate,
              "click-handler": () =>
                _vm.$bvModal.show("modal-lg-create-integration"),
            },
          }),
          _c("SearchInputField", {
            attrs: { id: "search-exports", data: _vm.exports },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table__wrapper" },
        [
          _c("CustomTable", {
            attrs: {
              data: _vm.exports,
              "type-of-table": "exports",
              "is-pagination-enabled": true,
              "is-loading": _vm.isLoading,
              "empty-state-action": {
                canAdd: _vm.canCreate,
                action: () => _vm.$bvModal.show("modal-lg-create-integration"),
              },
            },
            on: { "update-table": _vm.getData },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("ExportsRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("CustomModal", {
        attrs: {
          "select-options": _vm.providers,
          "modal-title": _vm.$t("data.exports.newExport"),
          "modal-text": _vm.$t("data.exports.chooseThirdParty"),
          type: "integration",
          "documentation-link": `${_vm.whitelabel.documentationUrl}/intro#introduction-to-data-exports`,
          "documentation-text": _vm.$t("data.exports.documentationLink"),
        },
        on: { "on-create": _vm.createExportForm },
      }),
      _c("DeleteModal", {
        attrs: {
          title: _vm.$t("data.exports.deleteExport"),
          type: _vm.$t("data.exports.export"),
        },
        on: { delete: _vm.triggerDelete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }