<template>
  <div>
    <span v-if="data.column.field === 'name'">
      <ReadMore
        :text="data.row.name"
        :classes="data.row.canEdit ? ['connections__name-column'] : []"
        :click-handler="
          data.row.canEdit
            ? () => {
                setConnectionData(data.row.id)
                $bvModal.show('modal-connections-create')
              }
            : () => {}
        "
      />
    </span>
    <!-- Column: Shared Data-->
    <span v-else-if="data.column.field === 'sharedData'">
      <span v-for="(data, index) in data.row.sharedData" :key="index">
        <span>{{ index === 1 ? `, ${data}` : data }}</span>
      </span>
    </span>
    <!-- Column: Date-->
    <div v-else-if="data.column.field === 'insertDateTime'" class="block-elem">
      <DateColumn :data="data"></DateColumn>
    </div>

    <!-- Column: status -->
    <span v-else-if="data.column.field === 'status'" class="status-and-actions">
      <div class="status-and-info">
        <div>
          <b-form-checkbox
            :id="`switch-status-${data.row.id}`"
            :disabled="!data.row.canEdit || isLoading"
            :checked="data.row.status ? true : false"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="
              data.row.canEdit
                ? setStatus(data.row.id, data.row.status ? 0 : 1)
                : {}
            "
          />
        </div>
        <InformationSidebar
          :title="$t('data.connections.disable')"
          :content="$t('data.connections.disableInformation')"
          :code="data.row.id"
        />
      </div>
    </span>

    <!-- Column: Info Status -->
    <span v-else-if="data.column.field === 'infoStatus'">
      <span v-if="data.row.infoStatus === 1" class="info__status">
        <div class="dot dot--enabled"></div>
        <span>{{ $t('enabled') }}</span>
      </span>
      <span v-else class="info__status">
        <span class="dot dot--disabled"></span>
        <span>{{ $t('disabled') }}</span>
      </span>
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<script>
import { connections } from '@/api'
import store from '@/store'
import {
  DateColumn,
  // utility components
  InformationSidebar,
  ReadMore,
  //utility functions
  formatDate
} from '@nodus/utilities-front'
import { BFormCheckbox } from 'bootstrap-vue'
import { getCurrentInstance, ref } from 'vue'

export default {
  props: {
    data: {
      type: [Array, Object]
    }
  },
  components: {
    BFormCheckbox,
    InformationSidebar,
    DateColumn,
    ReadMore
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(false)

    const setStatus = async (id, status) => {
      isLoading.value = true
      await connections.setStatus(organizationId, id, status)
      store
        .dispatch('connections/getMyConnections', { id: organizationId })
        .then(() => {
          isLoading.value = false
        })
    }

    const setConnectionData = async (id) => {
      store.commit('connections/SET_MODAL_LOADING', true)
      await store.dispatch('connections/getConnection', {
        id: organizationId,
        connectionId: id
      })
      store.commit('connections/SET_MODAL_LOADING', false)
    }

    return {
      formatDate,
      setStatus,
      setConnectionData,
      isLoading
    }
  }
}
</script>
