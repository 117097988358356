var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.data.column.field === "name"
      ? _c("span", { staticClass: "dark-gray-color" }, [
          _c(
            "div",
            { staticClass: "name__wrapper" },
            [
              _c("ReadMore", {
                attrs: { text: _vm.data.row.name, to: _vm.viewLocation },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "alias"
      ? _c("span", [_c("ReadMore", { attrs: { text: _vm.data.row.alias } })], 1)
      : _vm.data.column.field === "insertDateTime"
      ? _c("div", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
      : _vm.data.column.field === "status"
      ? _c("span", { staticClass: "status-and-actions" }, [
          _c(
            "div",
            { staticClass: "status-and-info" },
            [
              _c("b-form-checkbox", {
                staticClass: "custom-control-primary",
                attrs: {
                  id: `switch-status-${_vm.data.row.alias}`,
                  disabled: !_vm.data.row.canEnable || _vm.isLoading,
                  checked: !!_vm.data.row.status,
                  name: "check-button",
                  switch: "",
                },
                on: {
                  change: function ($event) {
                    _vm.data.row.canEnable
                      ? _vm.setStatus(
                          _vm.data.row.alias,
                          _vm.data.row.status ? 0 : 1
                        )
                      : {}
                  },
                },
              }),
              _c("InformationSidebar", {
                attrs: {
                  title: _vm.$t("data.recordsDisable"),
                  content: _vm.$t("data.recordsDisableInformation"),
                  code: _vm.data.row.alias,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "data__actions" },
            [_c("DropdownActions", { attrs: { items: _vm.dropdownActions } })],
            1
          ),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }