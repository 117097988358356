/* eslint-disable */
import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'name-column',
          width: '20%'
        },
        {
          label: 'Alias',
          field: 'alias',
          thClass: 'alias-column',
          width: '15%'
        },
        {
          label: 'Type',
          field: 'recordType',
          width: '10%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '45%'
        },
        {
          label: 'Actions',
          field: 'status',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    presets: {},
    canCreate: null,
    types: [],
    alias: [],
    record: [],
    profilerPipelines: [],
    pipelineParameters: [],
    trackingCode: '',
    isModalLoading: true
  },
  getters: {
    records: (state) => state.table,
    record: (state) => state.record,
    canCreate: (state) => state.canCreate,
    presets: (state) => state.presets,
    schemaTypes: (state) => state.types,
    trackingCode: (state) => state.trackingCode,
    profilerPipelines: (state) => state.profilerPipelines,
    pipelineParameters: (state) => state.pipelineParameters,
    modalLoading: (state) => state.isModalLoading
  },
  actions: {
    async getRecords({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v2/${id}/records?${queryParamString}`
      )

      commit('SET_RECORDS', destructJSONObject(response, 'records'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
    },
    async getRecord({ commit }, { id, recordId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/records/${recordId}`
      )
      commit('SET_RECORD', response.data.data)
      return response.data.data
    },
    async getPresets({ commit }, { id, typeId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/records/presets/${typeId}`
      )
      commit('SET_PRESETS', destructJSONObject(response, 'presets'))
    },
    async getSchemaRowTypes({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/records/schema/row/types`
      )
      commit('SET_SCHEMA_ROW_TYPES', destructJSONObject(response, 'rowTypes'))
    },
    async getTrackingCode({ commit }, { id, recordId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/records/schema/code/${recordId}`
      )
      commit('SET_TRACKING_CODE', destructJSONObject(response, 'payload'))
      commit('SET_IS_MODAL_LOADING', false)
    },
    async getProfilerPipelines({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/records/profiler/pipelines`
      )
      commit('SET_PROFILER_PIPELINES', destructJSONObject(response, 'models'))
    },
    async getPipelineParameters({ commit }, { organizationId, pipelineId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${organizationId}/records/profiler/pipeline/${pipelineId}`
      )
      commit('SET_PIPELINE_PARAMETERS', destructJSONObject(response, 'model'))
    },
    async getRecordPipelineJob(__, { organizationId, recordId, page = 0 }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${organizationId}/records/profiler/${recordId}/pipeline/job?page=${page}`
      )
      return response.data
    },
    async getAiFeatures(_, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/organizations/${id}/configurations/ai`
      )
      return response.data.data
    }
  },
  mutations: {
    SET_PRESETS(state, presets) {
      Object.keys(presets).forEach((preset) => {
        presets[preset].forEach((p) => {
          p.selected = true
        })
      })
      state.presets = presets
    },
    SET_RECORDS(state, records) {
      state.table.rows = records
    },
    SET_RECORD(state, record) {
      state.record = record
      if (record.recordPresets) {
        const presets = Object.assign({}, record.recordPresets)
        Object.keys(presets).forEach((preset) => {
          presets[preset] = presets[preset].reduce((acc, rec) => {
            acc.push({
              ...rec,
              disabled: rec.selected,
              selected: rec.selected || rec.isMandatory
            })
            return acc
          }, [])
        })
        state.presets = presets
      }
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_SCHEMA_ROW_TYPES(state, types) {
      const array = []
      Object.keys(types).forEach((type) => {
        array.push({ value: types[type].key, name: types[type].value })
      })
      state.types = array
    },
    SET_TRACKING_CODE(state, payload) {
      state.trackingCode = payload
    },
    SET_IS_MODAL_LOADING(state, value) {
      state.isModalLoading = value
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_PROFILER_PIPELINES(state, pipelines) {
      state.profilerPipelines = pipelines
    },
    SET_PIPELINE_PARAMETERS(state, pipeline) {
      state.pipelineParameters = pipeline.variables
    }
  }
}
