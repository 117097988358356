<template>
  <section>
    <div v-if="formState.isLoading" class="loading__container">
      <LoadingBar />
    </div>
    <div v-else class="form__wrapper">
      <div class="container__wrapper container__wrapper--height">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('data.basicInfo') }}</h5>
        </div>
        <div class="container__content">
          <b-col lg="6" class="input__wrapper pl-0">
            <label class="mr-1">{{ $t('Name') }} *</label>
            <b-form-input
              id="record-name"
              v-model.trim="recordsObject.name.value"
              :placeholder="$t('data.recordNamePlaceholder')"
              type="text"
              class="form__input"
              :class="{
                'form__input--invalid': recordsObject.name.errors.length > 0
              }"
              @input="handleFormInput(formState, [recordsObject.name])"
            />
            <div v-if="recordsObject.name.errors.length > 0">
              <span
                v-for="(error, i) in recordsObject.name.errors"
                :key="i"
                class="alias__info--error"
              >
                {{ error }}
              </span>
            </div>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <label class="mr-1">{{ $t('data.alias') }} *</label>
            <b-input-group
              :class="{
                border__error:
                  (!aliasValidation.isValid && aliasValidation.isProcessed) ||
                  recordsObject.alias.errors.length > 0
              }"
            >
              <template v-if="!recordId" #append>
                <b-input-group-text>
                  <span
                    v-if="
                      !aliasValidation.isProcessed ||
                      aliasValidation.isProcessing
                    "
                    :class="{ svg__animate: aliasValidation.isProcessing }"
                  >
                    <GjIcon name="LoaderAlt"></GjIcon>
                  </span>
                  <span
                    v-if="
                      aliasValidation.isValid &&
                      !aliasValidation.isProcessing &&
                      aliasValidation.isProcessed
                    "
                    ><GjIcon name="Check" class="icon__check"></GjIcon
                  ></span>
                  <span
                    v-if="
                      !aliasValidation.isValid &&
                      !aliasValidation.isProcessing &&
                      aliasValidation.isProcessed
                    "
                    ><GjIcon name="Close" class="icon__close"></GjIcon
                  ></span>
                </b-input-group-text>
              </template>
              <b-form-input
                id="record-alias"
                v-model="recordsObject.alias.value"
                :placeholder="$t('data.aliasPlaceholder')"
                :disabled="!!recordId"
                type="text"
                class="form__input"
                :class="{ 'form__input--alias': !recordId }"
                @keyup="formatRecordAlias(), validateRecordAlias()"
                @input="handleFormInput(formState, [recordsObject.alias])"
              />
            </b-input-group>
            <span
              v-if="
                !recordId &&
                (!aliasValidation.isProcessed || aliasValidation.isValid)
              "
              class="alias__info"
            >
              *{{ $t('data.aliasInfo') }}
            </span>
            <span v-if="!aliasValidation.isValid" class="alias__info--error">
              {{ aliasValidation.errorMessage }}
            </span>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <label>{{ $t('Description') }} </label>
            <b-form-textarea
              id="record-description"
              v-model="recordsObject.description.value"
              :placeholder="$t('data.descriptionPlaceholder')"
              class="form__input form__input-textarea record-textarea"
              :class="{
                'form__input--invalid':
                  recordsObject.description.errors.length > 0
              }"
              rows="3"
              no-resize
              @input="handleFormInput(formState, [recordsObject.description])"
            />
            <div
              v-for="(error, errIndex) in recordsObject.description.errors"
              :key="errIndex"
            >
              <span class="alias__info--error">{{ error }}</span>
            </div>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <label class="mr-1">{{ $t('data.recordType') }} *</label>
            <div class="records__wrapper">
              <div
                v-for="option in channelOptions"
                :key="option.id"
                :id="`${option.text}`"
                class="records__wrapper-content"
                :class="{
                  'records__wrapper-content--disabled':
                    !!recordId || option.disabled,
                  'records__wrapper-content--active':
                    option.id === recordsObject.recordChannel
                }"
                v-on="
                  !recordId && !option.disabled
                    ? { click: () => chooseRecord(option) }
                    : {}
                "
              >
                <input
                  :id="option.text"
                  v-model="recordsObject.recordChannel"
                  type="radio"
                  name="records"
                  :disabled="!!recordId || option.disabled"
                  :value="option.id"
                />
                <b-tooltip
                  v-if="option.disabled"
                  :target="option.text"
                  triggers="hover"
                  placement="top"
                >
                  <span>{{ $t('data.notEnabledMessage') }}</span>
                </b-tooltip>
                <div class="records__wrapper-content-info">
                  <GjIcon v-if="option.icon" :name="option.icon"></GjIcon>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.0702 16.25C14.4213 14.3994 16.0472 13 18 13C20.2091 13 22 14.7909 22 17C22 19.2091 20.2091 21 18 21C16.0472 21 14.4213 19.6006 14.0702 17.75H10.9441C10.9809 17.5054 11 17.2549 11 17C11 16.7451 10.9809 16.4946 10.9441 16.25H14.0702ZM15.6145 16.25H18C18.4142 16.25 18.75 16.5858 18.75 17C18.75 17.4142 18.4142 17.75 18 17.75H15.6145C15.933 18.7643 16.8806 19.5 18 19.5C19.3807 19.5 20.5 18.3807 20.5 17C20.5 15.6193 19.3807 14.5 18 14.5C16.8806 14.5 15.933 15.2357 15.6145 16.25Z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 13C6.48463 13 6.94914 13.0862 7.37902 13.2441L8.8135 10.8533C9.19787 11.1715 9.63064 11.4333 10.0991 11.626L8.66469 14.0168C9.48417 14.7492 10 15.8144 10 17C10 19.2091 8.20914 21 6 21C3.79086 21 2 19.2091 2 17C2 14.7909 3.79086 13 6 13ZM6 19.5C4.61929 19.5 3.5 18.3807 3.5 17C3.5 15.6193 4.61929 14.5 6 14.5C6.20119 14.5 6.39684 14.5238 6.58428 14.5686L5.35686 16.6143C5.14375 16.9695 5.25892 17.4302 5.61411 17.6433C5.9693 17.8564 6.42999 17.7413 6.6431 17.3861L7.87019 15.3409C8.26203 15.7823 8.5 16.3634 8.5 17C8.5 18.3807 7.38071 19.5 6 19.5Z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 7C16 8.18563 15.4842 9.25078 14.6647 9.98327L16.0991 12.374C15.6306 12.5667 15.1979 12.8286 14.8135 13.1468L13.379 10.7559C12.9491 10.9138 12.4846 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM12.6433 6.61424L13.8702 8.6591C14.262 8.21772 14.5 7.63665 14.5 7C14.5 5.61929 13.3807 4.5 12 4.5C10.6193 4.5 9.5 5.61929 9.5 7C9.5 8.38071 10.6193 9.5 12 9.5C12.2012 9.5 12.3968 9.47624 12.5842 9.43136L11.357 7.38599C11.1439 7.0308 11.2591 6.57011 11.6143 6.35699C11.9694 6.14388 12.4301 6.25906 12.6433 6.61424Z"
                    />
                  </svg>
                  <label>{{ option.text }}</label>
                </div>
              </div>
            </div>
          </b-col>
        </div>
      </div>
      <div class="container__wrapper container__wrapper--height">
        <div class="container__header documentation__header--padding">
          <h5 class="container__header-title">{{ $t('data.schema') }} *</h5>
        </div>
        <div class="documentation__info-text mb-50">
          <GjIcon name="Info" class="mr-50" size="18" />
          <span
            >{{ $t('data.recordsDocumentationLink') }}
            <a
              :href="`${whitelabel.documentationUrl}/intro#introduction-to-records`"
              target="_blank"
              >{{ $t('Documentation').toLowerCase() }}</a
            >.</span
          >
        </div>
        <UploadListModal
          upload-type="schema"
          :upload-function="records.uploadFile"
          upload-button-text="Upload CSV or JSON"
          :upload-info="$t('data.uploadInfo')"
          :file-types="['CSV', 'JSON']"
          :file-size-limit="1048576"
          :show-review-modal="false"
          @add-file-entries="addSchemaRowsFromFile"
        >
          <template #fileContent="data">
            <div>{{ data.fileContent }}</div>
          </template>
        </UploadListModal>
        <div class="schema__wrapper">
          <div class="record__table record__table--heading">
            <b-col lg="4" md="4" sm="4" class="record__table-column"
              ><span>{{ $t('Name') }}</span></b-col
            >
            <b-col lg="3" md="3" sm="3" class="record__table-column"
              ><span>{{ $t('data.type') }}</span></b-col
            >
            <b-col
              v-if="recordsObject.recordChannel === 4"
              lg="3"
              md="3"
              sm="3"
              class="record__table-column"
              ><span>{{ $t('data.designation') }}</span></b-col
            >
            <b-col lg="2" md="2" sm="2" class="record__table-column"
              ><span>{{ $t('Required') }}</span></b-col
            >
          </div>
          <draggable
            v-model="recordsObject.recordSchema"
            :move="onMove"
            handle=".draggable"
            ghost-class="disabled-ghost"
            animation="150"
            class="records__draggable-area"
          >
            <div
              v-for="(schema, index) in recordsObject.recordSchema"
              :key="index"
              class="record__table"
            >
              <b-col lg="4" md="4" sm="4" class="record__table--name-col">
                <div
                  class="records__arrow"
                  :class="{
                    'records__arrow--multiple':
                      recordsObject.recordSchema.length > 1 && index !== 0
                  }"
                ></div>
                <div
                  :class="{
                    'records__arrow--or':
                      index === recordsObject.recordSchema.length - 1
                  }"
                >
                  <b-button
                    v-if="index === recordsObject.recordSchema.length - 1"
                    id="add-schema-row"
                    class="btn records__or-btn"
                    @click="addRow()"
                  >
                    <GjIcon name="Plus" size="18"></GjIcon>
                  </b-button>
                </div>
                <b-form-input
                  id="schema-name"
                  v-model="schema.name.value"
                  placeholder="Enter name"
                  type="text"
                  class="form__input"
                  :class="{
                    'form__input--invalid': schema.name.errors.length > 0
                  }"
                  :disabled="!!recordId && schema.disabled"
                  @blur="
                    schema.name.value = schema.name.value
                      .trim()
                      .replaceAll(/\s+/g, '_')
                  "
                  @input="
                    handleFormInput(formState, [
                      schema.name,
                      recordsObject.recordSchemas
                    ])
                  "
                />
                <div v-if="schema.name.errors.length > 0">
                  <span
                    v-for="(errors, i) in schema.name.errors"
                    :key="i"
                    class="alias__info--error"
                  >
                    <span class="alias__info--error__message">{{
                      errors
                    }}</span>
                  </span>
                </div>
              </b-col>
              <b-col lg="3" md="3" sm="3" class="record__table-column--width">
                <v-select
                  id="schema-type"
                  v-model="schema.type.value"
                  :options="schemaTypes"
                  :disabled="!!recordId && schema.disabled"
                  :clearable="false"
                  label="name"
                  placeholder="Select"
                  class="form__input segment__select record__select-type error"
                  :class="{
                    'form__input--invalid': schema.type.errors.length > 0
                  }"
                  @input="handleFormInput(formState, [schema.type])"
                ></v-select>
                <div v-if="schema.type.errors.length > 0">
                  <span
                    v-for="(errors, i) in schema.type.errors"
                    :key="i"
                    class="alias__info--error"
                  >
                    <span class="alias__info--error__message">{{
                      errors
                    }}</span>
                  </span>
                </div>
              </b-col>
              <b-col
                v-if="recordsObject.recordChannel === 4"
                lg="3"
                md="3"
                sm="3"
                class="record__table-column--width designation__column"
              >
                <v-select
                  v-model="schema.designation.value"
                  :options="
                    designationOptions.filter(
                      (opt) =>
                        !recordsObject.recordSchema.some(
                          (schema) => schema.designation.value === opt
                        )
                    )
                  "
                  :disabled="!!recordId && schema.disabled"
                  label="text"
                  :placeholder="schema.disabled ? 'null' : 'Select'"
                  class="form__input segment__select record__select-type error"
                  @input="handleFormInput(formState)"
                >
                  <template #option="data">
                    <div class="designation__select">
                      <img :src="data.icon" />
                      <span>{{ data.text }}</span>
                    </div>
                  </template>
                  <template #selected-option="data">
                    <div class="designation__select">
                      <img :src="data.icon" />
                      <span>{{ data.text }}</span>
                    </div>
                  </template>
                </v-select>
              </b-col>
              <b-col lg="2" md="2" sm="2" class="record__table-last__column">
                <div class="record__table-last__column-wrapper">
                  <b-form-checkbox
                    v-model="schema.required.value"
                    :disabled="!!recordId"
                    @input="handleFormInput(formState)"
                  ></b-form-checkbox>
                </div>
                <div
                  v-if="
                    recordsObject.recordSchema.length > 1 && !schema.disabled
                  "
                  class="record__table--actions"
                >
                  <GjIcon name="Drag" size="18" class="draggable"></GjIcon>
                  <span id="remove-schema-row" @click="removeRow(index)">
                    <GjIcon name="Close" size="18"></GjIcon
                  ></span>
                </div>
              </b-col>
            </div>
          </draggable>
          <div
            :class="{
              'form__input--invalid':
                recordsObject.recordSchemas.errors.length > 0
            }"
            style="display: hidden"
          ></div>
          <div
            v-if="recordsObject.recordSchemas.errors.length > 0"
            class="records-schema__general-error"
          >
            <span
              v-for="(error, index) in recordsObject.recordSchemas.errors"
              :key="index"
              class="alias__info--error"
            >
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="pipelineNotFound" class="profiler__error-message">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2517 5.147L3.65068 17.0287C2.91009 18.3618 3.87403 20 5.39899 20H18.6009C20.1259 20 21.0898 18.3618 20.3493 17.0287L13.7483 5.147C12.9863 3.77538 11.0137 3.77538 10.2517 5.147ZM13 9.00003C13 8.44775 12.5522 8.00003 12 8.00003C11.4477 8.00003 11 8.44775 11 9.00003V13C11 13.5523 11.4477 14 12 14C12.5522 14 13 13.5523 13 13V9.00003ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
            fill="#EF8C13"
          />
        </svg>
        <div>
          <h5>{{ $t('Warning') }}</h5>
          <p>{{ $t('data.tryRefreshingPage') }}.</p>
        </div>
      </div>
      <template v-else>
        <div
          v-if="recordsObject.recordChannel === 4"
          class="container__wrapper"
        >
          <div class="container__header">
            <h5 class="container__header-title">Integration</h5>
          </div>
          <b-col lg="6" class="input__wrapper pl-0">
            <label>{{ $t('data.pipelineIntegration') }} *</label>
            <v-select
              v-model="recordsObject.profilerPipeline"
              :options="profilerPipelines"
              :disabled="
                !!recordId ||
                (parametersLoading && !recordsObject.profilerPipeline)
              "
              :clearable="false"
              label="title"
              :loading="parametersLoading"
              placeholder="Select Integration"
              class="form__input segment__select"
              :class="{
                'form__input--invalid': !!recordsObject.pipelineId.errors.length
              }"
              @input="
                (pipeline) => {
                  getPipelineParameters(pipeline)
                  handleFormInput(formState, [recordsObject.pipelineId])
                }
              "
            >
              <template #spinner="{ loading }">
                <LoadingBar v-if="loading" classList="small mr-1" />
              </template>
            </v-select>
            <div v-if="recordsObject.pipelineId.errors.length > 0">
              <span
                v-for="(error, i) in recordsObject.pipelineId.errors"
                :key="i"
                class="alias__info--error"
              >
                {{ error }}
              </span>
            </div>
          </b-col>
          <template v-if="recordsObject.profilerPipeline && !parametersLoading">
            <b-col
              v-for="parameter in recordsObject.pipelineParameters"
              :key="parameter.name"
              lg="6"
              class="input__wrapper pl-0"
            >
              <label>{{ parameter.name }}</label>
              <b-form-input
                v-model.trim="parameter.value"
                :placeholder="`Enter ${parameter.name}`"
                type="text"
                class="form__input"
                @input="handleFormInput(formState)"
              />
            </b-col>
          </template>
        </div>
        <div
          v-if="recordsObject.recordChannel === 4"
          class="container__wrapper"
        >
          <div class="container__header">
            <h5 class="container__header-title">
              {{ $t('audience.schedule') }}
            </h5>
          </div>
          <div class="container__schedule">
            <div class="schedule__inputs">
              <span class="container__schedule__label-text">{{
                $t('data.runDataImport')
              }}</span>
              <div class="input__wrapper">
                <v-select
                  id="query-schedule"
                  v-model="dataImportSchedule.operator"
                  :options="reprocessOperatorOptions"
                  label="text"
                  class="form__input"
                  :class="{
                    'form__input--invalid':
                      recordsObject.scheduleId.errors.length
                  }"
                  :clearable="false"
                  @input="
                    handleFormInput(formState, [
                      recordsObject.scheduleFrequency,
                      recordsObject.scheduleId
                    ])
                  "
                ></v-select>
              </div>
              <div
                v-if="
                  dataImportSchedule.operator &&
                  dataImportSchedule.operator.text === 'Every'
                "
                id="reprocessValue"
                class="input__wrapper"
              >
                <b-form-input
                  id="schedule-number"
                  v-model="dataImportSchedule.value"
                  placeholder="2"
                  type="number"
                  min="1"
                  class="form__input-number"
                  :class="{
                    'form__input--invalid':
                      recordsObject.scheduleFrequency.errors.length
                  }"
                  @input="
                    handleFormInput(formState, [
                      recordsObject.scheduleFrequency,
                      recordsObject.scheduleId
                    ])
                  "
                />
              </div>
              <div
                v-if="
                  dataImportSchedule.operator &&
                  dataImportSchedule.operator.text === 'Every'
                "
                class="input__wrapper"
              >
                <v-select
                  id="schedule-time-unit"
                  v-model="dataImportSchedule.timeUnit"
                  :placeholder="$t('Select')"
                  :options="reprocessTimeUnitOptions"
                  label="text"
                  class="form__input"
                  :clearable="false"
                  :class="{
                    'form__input--invalid':
                      recordsObject.scheduleId.errors.length
                  }"
                  @input="
                    handleFormInput(formState, [
                      recordsObject.scheduleFrequency,
                      recordsObject.scheduleId
                    ])
                  "
                ></v-select>
              </div>
            </div>
            <div class="container__schedule__info-text">
              <span>
                {{ $t('data.dataImportWillRun') }}
                <b>{{
                  dataImportSchedule.operator.text === 'Once'
                    ? 'only once'
                    : +dataImportSchedule.value === 1
                    ? `every ${dataImportSchedule.timeUnit.text.slice(0, -1)}`
                    : `every ${dataImportSchedule.value} ${dataImportSchedule.timeUnit.text}`
                }}</b>
              </span>
            </div>
            <div v-if="recordsObject.scheduleId.errors.length > 0">
              <span
                v-for="(error, i) in recordsObject.scheduleId.errors"
                :key="i"
                class="alias__info--error"
              >
                {{ error }}
              </span>
            </div>
            <div v-if="recordsObject.scheduleFrequency.errors.length > 0">
              <span
                v-for="(error, i) in recordsObject.scheduleFrequency.errors"
                :key="i"
                class="alias__info--error"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="Object.keys(recordsObject.recordPreset).length"
        class="container__wrapper"
      >
        <div class="presets__container">
          <h5 class="container__header-title">{{ $t('data.presets') }}</h5>
          <div class="presets__container-info">
            <GjIcon name="Info" class="mr-50" size="18" />
            <span>{{ $t('data.presetInfo') }}</span>
          </div>
          <div
            v-for="(presetGroup, key, i) in recordsObject.recordPreset"
            :key="key"
          >
            <div
              v-b-toggle="'accordion-' + key"
              class="presets-container__expandable-heading"
            >
              {{ key }}
            </div>
            <b-collapse :id="`accordion-${key}`">
              <div class="presets__wrapper">
                <div
                  v-for="(preset, index) in presetGroup"
                  :key="index"
                  class="presets__wrapper-content"
                >
                  <label>{{ preset.name }}</label>
                  <span v-if="!recordId"
                    >{{ $t('data.type') }}: {{ preset.type }}</span
                  >
                  <span v-else
                    >{{ $t('data.type') }}:
                    {{ getSchemaType(preset.type) }}</span
                  >
                  <div :id="`tooltip-${key}-${index}`">
                    <GjIcon name="Info" size="18"></GjIcon>
                  </div>
                  <b-tooltip
                    :target="`tooltip-${key}-${index}`"
                    triggers="hover"
                  >
                    {{ preset.description }}
                  </b-tooltip>
                  <b-form-checkbox
                    :id="`checkbox-${i}-${index}`"
                    v-model="preset.selected"
                    :selected="preset.selected"
                    :disabled="
                      preset.isMandatory || (!!recordId && preset.disabled)
                    "
                    @input="checkSelectAllState(presetGroup, i)"
                  >
                  </b-form-checkbox>
                </div>
                <div class="presets-container__expandable-heading--extra">
                  <span>{{ $t('data.presetSelection') }}</span>
                  <b-form-checkbox
                    v-model="presetsState.isChecked[i]"
                    :disabled="presetsState.isDisabled[i]"
                    name="selectAllCheckbox"
                    :aria-describedby="`checkbox-${key}-${i}`"
                    :aria-controls="`checkbox-${key}-${i}`"
                    class="records__checkbox-all"
                    @input="checkAll(key, presetsState.isChecked[i])"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <Footer
        :is-loading="formState.isSubmitting"
        :submitted="formState.isSubmitting"
        :cancel-id="`cancel-create-record`"
        :create-id="`add-record`"
        :edit-mode="!!recordId"
        :disable-button="!isComplete || pipelineNotFound"
        @back="cancel()"
        @create="!recordId ? createRecord(recordsObject) : triggerSaveModal()"
      />
    </div>

    <SaveChangesModal
      :type="$t('data.record')"
      @save="createRecord(recordsObject)"
    />
  </section>
</template>

<script>
/* eslint-disable no-param-reassign */
import { records } from '@/api'
import router from '@/router'
import store from '@/store'
import {
  BButton,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupText,
  BTooltip,
  VBToggle
} from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive, ref } from 'vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import {
  designationOptions,
  getRecordType,
  getSchemaType,
  presetDesignations,
  reprocessOperatorOptions,
  reprocessTimeUnitOptions
} from './RecordConfig'

import {
  Footer,
  GjIcon,
  LoadingBar,
  SaveChangesModal,
  UploadListModal,
  // utility functions
  appendErrors,
  confirmChanges,
  handleFormInput,
  removeErrors,
  scrollToElement,
  unFlattenObject
} from '@nodus/utilities-front'

import { whitelabel } from '@/utils/constants'

export default {
  name: 'Records',
  components: {
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BCollapse,
    BFormCheckbox,
    BTooltip,
    BInputGroup,
    BInputGroupText,
    draggable,
    // Select Component
    vSelect,
    GjIcon,
    Footer,
    SaveChangesModal,
    UploadListModal,
    LoadingBar
  },
  directives: {
    'b-toggle': VBToggle
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.formState.isSubmitted && !this.formState.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  async beforeRouteEnter(to, __, next) {
    if (!to.params.recordId) {
      if (store.getters['records/canCreate'] === null) {
        await store.dispatch('records/getRecords', {
          id: to.params.organizationId
        })
      }
      if (store.getters['records/canCreate']) next()
      else next('/error/401')
    } else {
      next()
    }
  },
  computed: {
    isComplete() {
      return this.aliasValidation.isValid
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, recordId } = vm.$route.params
    const { validateAlias } = records

    const presets = computed(() => store.getters['records/presets'])
    const schemaTypes = computed(() => store.getters['records/schemaTypes'])
    const profilerPipelines = computed(
      () => store.getters['records/profilerPipelines']
    )
    const pipelineParameters = computed(
      () => store.getters['records/pipelineParameters']
    )

    const parametersLoading = ref(false)

    const formState = reactive({
      isSubmitted: false,
      isSubmitting: false,
      isLoading: false,
      isPristine: true
    })

    const presetsState = reactive({
      isChecked: [],
      isDisabled: []
    })

    const aliasValidation = reactive({
      isProcessing: false,
      isProcessed: false,
      isValid: false,
      errorMessage: ''
    })

    const filteredPresets = computed(() => {
      let filteredKeys = []
      // eslint-disable-next-line no-use-before-define
      const { recordChannel, recordSchema } = recordsObject
      const presetKeys = Object.keys(presets.value)
      const selectedDesignations = recordSchema.map(
        (schema) => schema.designation.value
      )

      if (getRecordType(recordChannel) === 'Profiler') {
        filteredKeys = presetKeys.filter((key) =>
          selectedDesignations.some(
            (sd) => presetDesignations[key] === (sd?.value || -1)
          )
        )
      } else {
        filteredKeys = presetKeys
      }

      return filteredKeys.reduce(
        (acc, rec) => ({ ...acc, ...{ [rec]: presets.value[rec] } }),
        {}
      )
    })

    const recordsObject = reactive({
      name: {
        value: '',
        errors: []
      },
      alias: {
        value: '',
        errors: []
      },
      description: {
        value: '',
        errors: []
      },
      recordChannel: 1,
      recordSchema: [
        {
          name: {
            value: '',
            errors: []
          },
          type: {
            value: '',
            errors: []
          },
          required: {
            value: false,
            errors: []
          },
          designation: {
            value: null,
            errors: []
          }
        }
      ],
      recordPreset: filteredPresets,
      recordSchemas: {
        errors: []
      },
      scheduleId: {
        errors: []
      },
      scheduleFrequency: {
        errors: []
      },
      pipelineId: {
        errors: []
      },
      profilerPipeline: null,
      pipelineParameters: recordId ? [] : pipelineParameters
    })

    const dataImportSchedule = reactive({
      operator: { text: 'Once', value: 1 },
      timeUnit: { text: 'hours', value: 3 },
      value: 2
    })

    const channelOptions = [
      { id: 1, text: 'Web', icon: 'Webpage' },
      { id: 2, text: 'Mobile', icon: 'Mobile' },
      { id: 3, text: 'API', icon: 'Api' },
      { id: 4, text: 'Profiler', icon: null }
    ]

    const checkSelectAllState = (currentPresets, index) => {
      let selectState = !presetsState.isChecked[index]

      // eslint-disable-next-line no-unused-expressions
      !selectState
        ? currentPresets.forEach((preset) => {
            if (!preset.isMandatory && !preset?.disabled)
              selectState ||= preset.selected
          })
        : currentPresets.forEach((preset) => {
            if (!preset.isMandatory && !preset?.disabled)
              selectState &&= preset.selected
          })

      presetsState.isChecked[index] = selectState
      formState.isPristine = false
    }

    const populateSchema = (rows, isEditing = true) => {
      const { length } = recordsObject.recordSchema

      if (length && !isEditing) {
        const { name, type, required, designation } =
          recordsObject.recordSchema[length - 1]
        if (!name.value && !type.value && !required.value && !designation.value)
          recordsObject.recordSchema.pop()
      }

      return rows?.reduce((acc, rec) => {
        acc.push({
          name: { value: rec.name, errors: [] },
          type: {
            value: schemaTypes.value.find((m) => m.value === rec.type),
            errors: []
          },
          required: {
            value: !!recordId && !isEditing ? false : rec.required,
            errors: []
          },
          designation: {
            value: designationOptions.find(
              (opt) => opt.value === rec.designation
            ),
            errors: []
          },
          disabled: isEditing
        })
        return acc
      }, [])
    }

    onBeforeMount(async () => {
      formState.isLoading = true
      store.dispatch('records/getSchemaRowTypes', { id: organizationId })

      const { profilerIsEnabled } = await store.dispatch(
        'records/getAiFeatures',
        { id: organizationId }
      )
      channelOptions[3].disabled = !profilerIsEnabled

      if (!recordId)
        store.dispatch('records/getPresets', {
          id: organizationId,
          typeId: 1
        })

      if (recordId) {
        const getRecord = await store.dispatch('records/getRecord', {
          id: organizationId,
          recordId
        })
        recordsObject.name.value = getRecord.name
        recordsObject.description.value = getRecord.description
        recordsObject.alias.value = getRecord.alias
        recordsObject.recordChannel = getRecord.recordChannel
        recordsObject.recordSchema = populateSchema(getRecord.recordSchema)

        if (!getRecord.canEdit) router.push('/error/401')

        if (getRecordType(getRecord.recordChannel) === 'Profiler') {
          parametersLoading.value = true
          const response = await store.dispatch(
            'records/getRecordPipelineJob',
            {
              organizationId,
              recordId
            }
          )

          if (response.statusCode === 404) {
            recordsObject.profilerPipeline = 404
          } else {
            const { job } = response.data
            recordsObject.profilerPipeline = {
              id: job.pipelineId,
              title: job.pipelineTitle
            }
            recordsObject.pipelineParameters = job.values
            parametersLoading.value = false

            if (job.scheduleId === 1) {
              dataImportSchedule.operator = { text: 'Once', value: 1 }
            } else {
              dataImportSchedule.operator = { text: 'Every', value: 2 }
              dataImportSchedule.timeUnit = reprocessTimeUnitOptions.find(
                (opt) => opt.value === job.scheduleId
              )
              dataImportSchedule.value = job.scheduleFrequency
            }
          }
        }

        aliasValidation.isProcessed = true
        aliasValidation.isValid = true
      }

      Object.keys(presets.value).forEach((key, index) => {
        presetsState.isChecked.push(false)
        presetsState.isDisabled.push(false)
        checkSelectAllState(presets.value[key], index)
      })

      if (recordId) presetsState.isDisabled = [...presetsState.isChecked]
      formState.isLoading = false
      formState.isPristine = true
    })

    const chooseRecord = (option) => {
      if (recordsObject.recordChannel === option.id) return

      recordsObject.recordChannel = option.id
      store.dispatch('records/getPresets', {
        id: organizationId,
        typeId: option.id
      })
      if (getRecordType(option.id) === 'Profiler') {
        parametersLoading.value = true
        store
          .dispatch('records/getProfilerPipelines', { id: organizationId })
          .then(() => {
            parametersLoading.value = false
          })
      } else {
        recordsObject.profilerPipeline = null
      }
      formState.isPristine = false
    }

    const getPipelineParameters = async (pipeline) => {
      parametersLoading.value = true
      await store.dispatch('records/getPipelineParameters', {
        organizationId,
        pipelineId: pipeline.id
      })
      parametersLoading.value = false
      formState.isPristine = false
    }

    const addRow = () => {
      recordsObject.recordSchema.push({
        name: { value: '', errors: [] },
        type: { value: null, errors: [] },
        required: { value: false, errors: [] },
        designation: { value: null, errors: [] }
      })
      formState.isPristine = false
    }

    const removeRow = (rowIndex) => {
      recordsObject.recordSchema.splice(rowIndex, 1)
    }

    const addSchemaRowsFromFile = (entries) => {
      populateSchema(entries, false).forEach((entry) =>
        recordsObject.recordSchema.push(entry)
      )
      formState.isPristine = false
    }

    const createRecord = async (record) => {
      formState.isSubmitting = true
      const presetArray = []
      Object.keys(record.recordPreset).forEach((preset) => {
        record.recordPreset[preset].forEach((p) => {
          if (p.selected) {
            presetArray.push({ id: p.id })
          }
        })
      })

      const requestObject = {
        name: record.name.value,
        alias: record.alias.value,
        description: record.description.value,
        recordChannel: record.recordChannel,
        recordSchema: record.recordSchema?.reduce((acc, rec) => {
          acc.push({
            name: rec.name.value,
            type: rec.type.value?.value ? +rec.type.value.value : -1,
            required: rec.required.value,
            designation: rec.designation.value?.value
          })
          return acc
        }, []),
        recordPresets: presetArray,
        ...(getRecordType(record.recordChannel) === 'Profiler' && {
          pipelineId: record.profilerPipeline?.id || null,
          pipelineParameters: record.profilerPipeline
            ? record.pipelineParameters.reduce(
                (acc, rec) => ({ ...acc, ...{ [rec.name]: rec.value } }),
                {}
              )
            : null,
          scheduleId:
            dataImportSchedule.operator.text === 'Once'
              ? dataImportSchedule.operator.value
              : dataImportSchedule.timeUnit.value,
          scheduleFrequency: +dataImportSchedule.value
        })
      }

      let response = null
      if (recordId) {
        response = await records.updateRecord(
          organizationId,
          recordId,
          requestObject
        )
      } else {
        response = await records.createRecord(organizationId, requestObject)
      }

      const { errors, success } = response.data
      if (!success) {
        const errorsObject = unFlattenObject(errors)
        removeErrors(recordsObject)
        appendErrors(recordsObject, errorsObject)
        setTimeout(
          () => scrollToElement('[class*="form__input--invalid"]'),
          200
        )
        formState.isSubmitting = false
      } else {
        formState.isSubmitted = true
        router.push({
          name: 'records',
          params: { organizationId }
        })
      }
    }

    function debounce(func, timeout = 500) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeout)
      }
    }

    const saveInput = async () => {
      if (!recordId) {
        aliasValidation.isProcessing = true
        const {
          data: {
            data: { isValid, errors }
          }
        } = await validateAlias(organizationId, recordsObject.alias.value)
        if (!isValid) {
          const key = Object.keys(errors)
          aliasValidation.errorMessage = errors[key]
        }
        aliasValidation.isProcessing = false
        aliasValidation.isProcessed = true
        aliasValidation.isValid = isValid
      }
    }

    const validateRecordAlias = debounce(() => saveInput())

    const formatRecordAlias = () => {
      let alias = recordsObject.alias.value
      alias = alias.trim().length
        ? alias.replaceAll(/(\s+|_+)/g, '_')
        : alias.trim()
      recordsObject.alias.value = alias
    }

    const checkAll = (data, isChecked) => {
      recordsObject.recordPreset[data].forEach((preset) => {
        if (!preset.isMandatory && !preset?.disabled)
          preset.selected = isChecked
      })
    }

    const validateSchemaName = (name) => {
      const regex = /^[a-zA-Z]+\w*$/

      return regex.test(name)
    }

    const triggerSaveModal = () => {
      if (formState.isPristine)
        router.push({
          name: 'records',
          params: { organizationId }
        })
      else vm.$bvModal.show('save-changes-modal')
    }

    const onMove = ({ relatedContext, draggedContext }) =>
      !relatedContext.element.disabled && !draggedContext.element.disabled

    const pipelineNotFound = computed(
      () =>
        !!recordId &&
        recordsObject.recordChannel === 4 &&
        recordsObject.profilerPipeline === 404
    )

    const cancel = () => {
      router.push({ name: 'records' })
    }

    return {
      channelOptions,
      presetsState,
      schemaTypes,
      recordsObject,
      chooseRecord,
      formatRecordAlias,
      validateRecordAlias,
      addSchemaRowsFromFile,
      addRow,
      removeRow,
      createRecord,
      checkAll,
      aliasValidation,
      checkSelectAllState,
      validateSchemaName,
      organizationId,
      recordId,
      triggerSaveModal,
      onMove,
      handleFormInput,
      formState,
      getSchemaType,
      cancel,
      reprocessOperatorOptions,
      reprocessTimeUnitOptions,
      designationOptions,
      dataImportSchedule,
      records,
      profilerPipelines,
      getPipelineParameters,
      parametersLoading,
      pipelineNotFound,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
// @import "@/assets/libs/vue-select.scss";
@import '@/assets/scss/views/records/record-create.scss';
</style>
