export default [
  {
    path: '/bisko/:organizationId/data/exports',
    name: 'exports',
    component: () => import('@/views/exports/Exports'),
    meta: {
      navActiveLink: 'exports',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Exports',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/data/exports/:providerId/create',
    name: 'export-create',
    component: () => import('@/views/exports/ExportCreate'),
    meta: {
      navActiveLink: 'export-create',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Exports',
          to: '/exports'
        },
        {
          text: 'Create Export',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/data/exports/view/:exportId',
    name: 'export-view',
    component: () => import('@/views/exports/ExportView'),
    meta: {
      navActiveLink: 'export-view',
      pageTitle: 'Data',
      breadcrumb: [
        {
          text: 'Data'
        },
        {
          text: 'Exports',
          to: '/exports'
        },
        {
          text: 'View Export',
          active: true
        }
      ]
    }
  }
]
