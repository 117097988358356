import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'
import sharedConnections from './shared-connections'

export default {
  namespaced: true,
  modules: { sharedConnections },
  state: {
    myConnections: {
      columns: [
        {
          label: 'Connection Name',
          field: 'name',
          thClass: 'name-column',
          width: '20%'
        },
        {
          label: 'Organization Name',
          field: 'organizationName',
          width: '18%'
        },
        {
          label: 'Shared Data',
          field: 'sharedData',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '17%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '35%'
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    canCreate: false,
    IDToEdit: '',
    isEditing: false,
    isModalLoading: false,
    connection: {
      connectionName: {
        value: '',
        errors: []
      },
      organizationAlias: {
        value: '',
        errors: []
      },
      shareEvents: false,
      shareRecords: false,
      model: {
        errors: []
      }
    }
  },
  getters: {
    myConnections: (state) => state.myConnections,
    canCreate: (state) => state.canCreate,
    connection: (state) => state.connection,
    IDtoEdit: (state) => state.IDToEdit,
    isModalLoading: (state) => state.isModalLoading
  },
  actions: {
    async getMyConnections({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.myConnections.searchTerm,
        state.myConnections.page,
        state.myConnections.perPage,
        state.myConnections.orderField,
        state.myConnections.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/connections?${queryParamString}`
      )

      commit('SET_MY_CONNECTIONS', destructJSONObject(response, 'connections'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
    },
    async getConnection({ commit }, { id, connectionId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/connections/${connectionId}`
      )
      commit('SET_CONNECTION', response.data.data)
      commit('SET_ID_TO_EDIT', connectionId)
    }
  },
  mutations: {
    SET_MY_CONNECTIONS(state, connections) {
      state.myConnections.rows = connections
    },
    SET_CONNECTION(state, data) {
      state.connection.connectionName.value = data.connectionName
      state.connection.organizationAlias.value = data.sharedWithOrganization
      state.connection.shareEvents = data.shareEvents
      state.connection.shareRecords = data.shareRecords
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_ID_TO_EDIT(state, id) {
      state.IDToEdit = id
    },
    SET_MODAL_LOADING(state, value) {
      state.isModalLoading = value
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.myConnections.totalRecords = totalRecords
    }
  }
}
